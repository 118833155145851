import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Tooltip.module.css';
import EllipsedDiv from '../../../EllipsedDiv';

interface IProps {
  targetRect: DOMRect;
}

const Tooltip: React.FC<IProps> = ({targetRect, children}) => {
  const root = document.querySelector('#tooltip-slot');

  if (!root) {
    return null;
  }

  const positionStyles = {
    top: `${targetRect.top}px`,
    left: `${(targetRect.right + targetRect.left) / 2}px`,
  };

  return ReactDOM.createPortal(
    <div className={styles.container} style={positionStyles}>
      <EllipsedDiv>{children}</EllipsedDiv>
    </div>,
    root
  );
};

export default Tooltip;
