import IClaims from '../../interfaces/IClaims';

export const SAVE_TOKEN_CLAIMS = '[System] SAVE_TOKEN_CLAIMS';

export interface SaveTokenClaimsAction {
  type: typeof SAVE_TOKEN_CLAIMS;
  payload: IClaims;
}

export interface ISystemState {
  claims: IClaims | undefined;
}

export type SystemActionType = SaveTokenClaimsAction;
