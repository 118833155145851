import React from 'react';
import styles from './ExtensionNameWithStatus.module.css';
import {EXTENSION_STATUS} from '../../../../config/constants';
import ExtensionStatus from '../ExtensionStatus';

interface IProps {
  name: string;
  status: EXTENSION_STATUS;
}

const ExtensionNameWithStatus: React.FC<IProps> = ({name, status}) => {
  return (
    <div className={styles.container}>
      {name}
      <div className={styles.status}>
        <ExtensionStatus status={status} />
      </div>
    </div>
  );
};

export default ExtensionNameWithStatus;
