import {IField} from '../../../../interfaces/IField';
import IData from '../../../../interfaces/IData';

export const FIELDS_FETCH = '[Fields] FETCH';
export const FIELDS_FETCH_FAILURE = '[Fields] FETCH_FAILURE';
export const FIELDS_FETCH_SUCCESS = '[Fields] FETCH_SUCCESS';
export const FIELDS_UPDATE = '[Fields] UPDATE_FIELDS';
export const FIELDS_UPDATE_SUCCESS = '[Fields] FIELDS_UPDATE_SUCCESS';
export const FIELDS_UPDATE_FAILURE = '[Fields] FIELDS_UPDATE_FAILURE';
export const UPDATE_FIELD = '[Fields] UPDATE_FIELD';
export const CLEAR_FIELDS_STATE = '[Fields] CLEAR_STATE';

export interface FieldsFetchAction {
  type: typeof FIELDS_FETCH;
}

export interface FieldsFetchFailureAction {
  type: typeof FIELDS_FETCH_FAILURE;
  error: string;
}

export interface FieldsFetchSuccessAction {
  type: typeof FIELDS_FETCH_SUCCESS;
  payload: IData<IField>;
}

export interface FieldsUpdateAction {
  type: typeof FIELDS_UPDATE;
}

export interface FieldsUpdateFailureAction {
  type: typeof FIELDS_UPDATE_FAILURE;
  error: string;
}

export interface FieldsUpdateSuccessAction {
  type: typeof FIELDS_UPDATE_SUCCESS;
}

export interface UpdateFieldAction {
  type: typeof UPDATE_FIELD;
  payload: IField;
}

export interface ClearFieldsStateAction {
  type: typeof CLEAR_FIELDS_STATE;
}

export interface IFieldsState {
  origFields: IData<IField>;
  fields: IData<IField>;
  fieldsToUpdate: IField[];
  isFetching: boolean;
  isUpdating: boolean;
}

export type FieldsActionType =
  | FieldsFetchAction
  | FieldsFetchSuccessAction
  | FieldsFetchFailureAction
  | ClearFieldsStateAction
  | UpdateFieldAction
  | FieldsUpdateAction
  | FieldsUpdateFailureAction
  | FieldsUpdateSuccessAction
  | ClearFieldsStateAction;
