import React from 'react';
import IEntity from '../../../../interfaces/IEntity';
import {Tr, Td} from '../../../../components/Table';
import {IConfiguration} from '../../../../interfaces/IConfiguration';
import {Link} from 'react-router-dom';
import paths from '../../../../config/router/paths';
import WithTooltip from '../../../../components/WithTooltip';
import IconButton from '../../../../components/IconButton';
import Icon from '../../../../components/Icon';
import useModal from '../../../../hooks/useModal';
import {DeleteModal} from '../../../../components/Modals';
import {sendAuthorizedRequest} from '../../../../utils/request';
import EditPrintModal from '../EditPrintModal';
import {LanguageList} from '../../../../components/LanguageList';
import DownloadXMLModal from '../DownloadXMLModal';
import IXmlLink from '../../../../interfaces/IXmlLink';
import EntityLink from '../../../../components/EntityLink';

interface IProps {
  id: number;
  name: string;
  configuration: IConfiguration;
  entity: IEntity;
  codes: string[];
  links: IXmlLink[];

  onUpdate: () => void;
  onDelete: () => void;
}

const PrintRow: React.FC<IProps> = ({id, name, configuration, entity, links, codes, onUpdate, onDelete}) => {
  const [isDeletePrintModalOpen, openDeletePrintModal, closeDeletePrintModal] = useModal();
  const [isEditPrintModalOpen, openEditPrintModal, closeEditPrintModal] = useModal();
  const [isDownloadModalOpen, openDownloadModal, closeDownloadModal] = useModal();

  const onPrintDelete = async () => {
    try {
      await sendAuthorizedRequest(`/Print?id=${id}`, {
        method: 'DELETE',
      });

      onDelete();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      {isDeletePrintModalOpen && (
        <DeleteModal
          title={`Are you sure you want to delete the '${name}' with id ${id}?`}
          onClose={closeDeletePrintModal}
          onDelete={onPrintDelete}
        />
      )}
      {isDownloadModalOpen && <DownloadXMLModal links={links} onClose={closeDownloadModal} />}
      {isEditPrintModalOpen && (
        <EditPrintModal
          id={id}
          title={name}
          configuration={configuration}
          entity={entity}
          codes={codes}
          onSuccess={onUpdate}
          onClose={closeEditPrintModal}
        />
      )}
      <Tr>
        <Td>{name}</Td>
        <Td>
          <Link to={paths.configuration(configuration.id)}>{configuration.name}</Link>
        </Td>
        <Td>
          <EntityLink entity={entity} />
        </Td>
        <Td>
          <LanguageList codes={codes} />
        </Td>
        <Td align="flex-end">
          <WithTooltip tip="Download">
            <IconButton onClick={openDownloadModal}>
              <Icon type="download" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip>
          <WithTooltip tip="Edit">
            <IconButton onClick={openEditPrintModal}>
              <Icon type="pencil" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip>
          <WithTooltip tip="Delete">
            <IconButton onClick={openDeletePrintModal}>
              <Icon type="trash" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip>
        </Td>
      </Tr>
    </>
  );
};

export default PrintRow;
