import React from 'react';
import styles from './ExtenstionDescription.module.css';

interface IProps {
  description: string;
}

const ExtenstionDescription: React.FC<IProps> = ({description}) => {
  return (
    <div
      className={styles.container}
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    />
  );
};

export default ExtenstionDescription;
