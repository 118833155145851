import React, {useEffect} from 'react';
import styles from './Prints.module.css';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import CustomHelmet from '../../components/CustomHelmet';
import Pagination from '../../components/Pagination';
import {Table, THead, TBody, Th, Tr, SortableTh} from '../../components/Table';
import useSearch from '../../hooks/useSearch';
import usePagination from '../../hooks/usePagination';
import Icon from '../../components/Icon';
import Loadable from '../../components/Loadable';
import DebouncedInput from '../../components/Inputs';
import usePrints from '../../hooks/usePrints';
import useModal from '../../hooks/useModal';
import CreatePrintModal from './components/CreatePrintModal';
import PrintRow from './components/PrintRow';
import Scrollable from '../../components/Scrollable';
import useSort from '../../hooks/useSort';
import {useLocation, useHistory} from 'react-router-dom';
import queryString from 'query-string';
import paths from '../../config/router/paths';

const Prints: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [search, setSearch] = useSearch('');
  const [page, setPage] = usePagination(0);
  const [printsData, isLoading, getPrints] = usePrints();
  const [isCreatePrintModalOpen, openCreatePrintModal, closeCreatePrintModal] = useModal();
  const [sortConf, sortConfArray, updateSort] = useSort({
    name: undefined,
    createDate: undefined,
    modifyDate: undefined,
  });

  const refetchPrints = () => getPrints(page, search, sortConfArray);

  useEffect(() => {
    getPrints(page, search, sortConfArray);
  }, [search, page, sortConfArray, getPrints]);

  useEffect(() => {
    const parsedLocation = queryString.parse(location.hash);

    if (parsedLocation.newPrint !== undefined) {
      openCreatePrintModal();
      history.push(paths.prints());
    }
  }, [location.hash, history, openCreatePrintModal]);

  return (
    <div className={styles.container}>
      <CustomHelmet title="Magnispot | Prints" />
      {isCreatePrintModalOpen && <CreatePrintModal onClose={closeCreatePrintModal} onSuccess={refetchPrints} />}
      <div className={styles.topBar}>
        <div className={styles.topLeft}>
          <DebouncedInput onChange={setSearch} placeholder="Search" />
        </div>
        <div className={styles.topRight}>
          <ButtonWithIcon title="New Print" icon={<Icon type="plus" width={14} height={14} fill="#fff" />} onClick={openCreatePrintModal} />
        </div>
      </div>
      <Scrollable>
        <Table className={styles.table}>
          <THead>
            <Tr>
              <SortableTh name="name" sortConf={sortConf} onClick={updateSort}>
                Name
              </SortableTh>
              <Th>Configuration</Th>
              <Th>Source</Th>
              <Th>Languages</Th>
              <Th></Th>
            </Tr>
          </THead>

          <Loadable loadingFlags={[isLoading]} centered absolute>
            <TBody>
              {printsData.items.map((p) => (
                <PrintRow
                  key={p.id}
                  id={p.id}
                  name={p.name}
                  configuration={p.configuration}
                  entity={p.entity}
                  links={p.links}
                  codes={p.codes}
                  onUpdate={refetchPrints}
                  onDelete={refetchPrints}
                />
              ))}
            </TBody>
          </Loadable>
        </Table>
      </Scrollable>
      <Pagination
        page={page}
        pageCount={printsData.totalPages}
        totalCount={printsData.totalCount}
        onPageChange={setPage}
        dataName="prints"
      />
    </div>
  );
};

export default Prints;
