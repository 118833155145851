import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {cookies} from '../../config/constants';
import IClaims from '../../interfaces/IClaims';
import {ISystemState, SystemActionType, SAVE_TOKEN_CLAIMS} from './types';

const aToken = Cookies.get(cookies.accessToken);
let claims: IClaims | undefined;

if (aToken) {
  claims = jwtDecode(aToken);
}

const initialState = {
  claims,
};

const system = (state: ISystemState = initialState, action: SystemActionType): ISystemState => {
  switch (action.type) {
    case SAVE_TOKEN_CLAIMS:
      return {
        ...state,
        claims: action.payload,
      };
    default:
      return state;
  }
};

export default system;
