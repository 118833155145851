import React, {useEffect} from 'react';
import styles from './Configurations.module.css';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import CustomHelmet from '../../components/CustomHelmet';
import Pagination from '../../components/Pagination';
import {Table, THead, TBody, Th, Tr, SortableTh} from '../../components/Table';
import CreateConfigurationModal from './components/CreateConfigurationModal';
import ConfigurationRow from './components/ConfigurationRow';
import useSearch from '../../hooks/useSearch';
import useConfigurations from '../../hooks/useConfigurations';
import usePagination from '../../hooks/usePagination';
import Icon from '../../components/Icon';
import Loadable from '../../components/Loadable';
import useModal from '../../hooks/useModal';
import DebouncedInput from '../../components/Inputs';
import Scrollable from '../../components/Scrollable';
import useSort from '../../hooks/useSort';

const Configurations: React.FC = () => {
  const [isNewConfModalOpen, openNewConfModal, closeNewConfModal] = useModal();
  const [search, setSearch] = useSearch('');
  const [configurationData, isLoading, getConfigurations] = useConfigurations();
  const [page, setPage] = usePagination(0);
  const [sortConf, sortConfArray, updateSort] = useSort({
    name: undefined,
    createdBy: undefined,
    createDate: undefined,
    modifyDate: undefined,
    updatedBy: undefined,
  });

  const onSearchChange = (value: string) => {
    setPage(0);
    setSearch(value);
  };

  const refetchConfigurations = () => getConfigurations(page, search, []);

  useEffect(() => {
    getConfigurations(page, search, sortConfArray);
  }, [search, page, sortConfArray, getConfigurations]);

  return (
    <div className={styles.container}>
      {isNewConfModalOpen && <CreateConfigurationModal onClose={closeNewConfModal} onSuccess={refetchConfigurations} />}
      <CustomHelmet title="Magnispot | Configurations" />
      <div className={styles.topBar}>
        <div className={styles.topLeft}>
          <DebouncedInput onChange={onSearchChange} placeholder="Search" />
        </div>
        <div className={styles.topRight}>
          <ButtonWithIcon
            title="New Configuration"
            icon={<Icon type="plus" width={14} height={14} fill="#fff" />}
            onClick={openNewConfModal}
          />
        </div>
      </div>
      <Scrollable>
        <Table className={styles.table}>
          <THead>
            <Tr>
              <SortableTh name="name" sortConf={sortConf} onClick={updateSort}>
                Name
              </SortableTh>
              <SortableTh name="createDate" sortConf={sortConf} onClick={updateSort}>
                Created At
              </SortableTh>
              <SortableTh name="createdBy" sortConf={sortConf} onClick={updateSort}>
                Created By
              </SortableTh>
              <SortableTh name="modifyDate" sortConf={sortConf} onClick={updateSort}>
                Updated At
              </SortableTh>
              <SortableTh name="updatedBy" sortConf={sortConf} onClick={updateSort}>
                Updated By
              </SortableTh>
              <Th></Th>
            </Tr>
          </THead>

          <Loadable loadingFlags={[isLoading]} centered absolute>
            <TBody>
              {configurationData.items.map((c) => (
                <ConfigurationRow
                  key={c.id}
                  id={c.id}
                  title={c.name}
                  createdAt={c.createDate}
                  createdBy={c.createdBy}
                  modifyDate={c.modifyDate}
                  updatedBy={c.updatedBy}
                  onDuplicate={refetchConfigurations}
                  onDelete={refetchConfigurations}
                  onUpdate={refetchConfigurations}
                />
              ))}
            </TBody>
          </Loadable>
        </Table>
      </Scrollable>
      <Pagination
        page={page}
        pageCount={configurationData.totalPages}
        totalCount={configurationData.totalCount}
        onPageChange={setPage}
        dataName="configurations"
      />
    </div>
  );
};

export default Configurations;
