import React from 'react';
import styles from './Table.module.css';

interface IProps {
  align?: 'left' | 'center' | 'right';
}

const Th: React.FC<IProps> = ({align, children}) => {
  const customStyles = {
    textAlign: align,
  };

  return (
    <div className={styles.th} style={customStyles}>
      {children}
    </div>
  );
};

export default Th;
