import React from 'react';
import {Tr, Td} from '../../../../components/Table';
import WithTooltip from '../../../../components/WithTooltip';
import IconButton from '../../../../components/IconButton';
import Icon from '../../../../components/Icon';
import useModal from '../../../../hooks/useModal';
import {DeleteModal} from '../../../../components/Modals';
import EditExtensionModal from '../EditExtensionModal';
import DetailsExtensionModal from '../DetailsExtensionModal';
import ExtensionStatus from '../ExtensionStatus';
import {EXTENSION_STATUS} from '../../../../config/constants';
import {sendAuthorizedRequest} from '../../../../utils/request';

interface IProps {
  id: number;
  name: string;
  description: string;
  status: EXTENSION_STATUS;

  onEdit: () => void;
  onDelete: () => void;
}

const ExtenstionRow: React.FC<IProps> = ({id, name, description, status, onEdit, onDelete}) => {
  const [isDeleteExtensionModalOpen, openDeleteExtensionModal, closeDeleteExtensionModal] = useModal();
  const [isEditExtensionModalOpen, openEditExtensionModal, closeEditExtensionModal] = useModal();
  const [isDetailsExtensionModalOpen, openDetailsExtensionModal, closeDetailsExtensionModal] = useModal();

  const onExtensionDelete = async () => {
    try {
      await sendAuthorizedRequest(`/Extension?id=${id}`, {
        method: 'DELETE',
      });

      onDelete();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {isDeleteExtensionModalOpen && (
        <DeleteModal
          title={`Are you sure you want to delete the formula ${name}?`}
          onClose={closeDeleteExtensionModal}
          onDelete={onExtensionDelete}
        />
      )}
      {isEditExtensionModalOpen && (
        <EditExtensionModal
          id={id}
          name={name}
          status={status}
          description={description}
          onSuccess={onEdit}
          onClose={closeEditExtensionModal}
        />
      )}
      {isDetailsExtensionModalOpen && (
        <DetailsExtensionModal name={name} description={description} status={status} onClose={closeDetailsExtensionModal} />
      )}
      <Tr>
        <Td>{name}</Td>
        <Td>
          <ExtensionStatus status={status} />
        </Td>
        <Td align="flex-end">
          <WithTooltip tip="Details">
            <IconButton onClick={openDetailsExtensionModal}>
              <Icon type="info" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip>
          {/* <WithTooltip tip="Test Formula">
            <IconButton onClick={openTestExtensionModal}>
              <Icon type="test-tube" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip> */}
          <WithTooltip tip="Edit">
            <IconButton onClick={openEditExtensionModal}>
              <Icon type="pencil" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip>
          <WithTooltip tip="Delete">
            <IconButton onClick={openDeleteExtensionModal}>
              <Icon type="trash" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip>
        </Td>
      </Tr>
    </>
  );
};

export default ExtenstionRow;
