import React, {useState, useEffect} from 'react';
import styles from './Source.module.css';
import SourceType from '../../types/SourceType';
import {RouteComponentProps} from 'react-router';
import queryString from 'query-string';
import useEntities from '../../hooks/useEntities';
import useSearch from '../../hooks/useSearch';
import useEntityTypes from '../../hooks/useEntityTypes';
import usePagination from '../../hooks/usePagination';
import CustomHelmet from '../../components/CustomHelmet';
import Loadable from '../../components/Loadable';
import TypesBar from './components/TypesBar';
import ClearableInput from '../../components/ClearableInput';
import Grid from '../../components/Grid';
import {debounce} from 'debounce';
import Pagination from '../../components/Pagination';
import Scrollable from '../../components/Scrollable';
import IEntity from '../../interfaces/IEntity';
import Entity from '../../components/Entity';
import EntityInfoSidebar from '../../components/EntityInfoSidebar';

const Source: React.FC<RouteComponentProps> = ({location}) => {
  const defaultFilter = queryString.parse(location.search);
  const [entityTypes, isTypesLoading, getEntityTypes] = useEntityTypes();
  const [entitiesData, isEntitiesLoading, getEntities] = useEntities();
  const [page, setPage] = usePagination(0);
  const [type, setType] = useState<SourceType | undefined>(defaultFilter.type as SourceType | undefined);
  const [search, setSearch] = useSearch('');
  const [sidebarEntity, setSidebarEntity] = useState<IEntity | undefined>();

  const onSearchChange = (value: string) => {
    setPage(0);
    setSearch(value);
  };

  const onTypeChange = (type: SourceType | undefined) => {
    setPage(0);
    setType(type);
  };

  const onInfoShow = (entity: IEntity) => setSidebarEntity(entity);
  const onInfoHide = () => setSidebarEntity(undefined);

  useEffect(() => {
    getEntityTypes();
  }, [getEntityTypes]);

  useEffect(() => {
    getEntities(page, search, type);
  }, [search, page, type, getEntities]);

  return (
    <div className={styles.container}>
      <CustomHelmet title="Magnispot | Source" />
      <div className={styles.sidebar}>
        <Loadable loadingFlags={[isTypesLoading]} secondary>
          <TypesBar types={entityTypes} onChange={onTypeChange} selectedType={type} />
        </Loadable>
      </div>
      <div className={styles.content}>
        <div className={styles.searchBar}>
          <ClearableInput onChange={debounce(onSearchChange, 500)} />
        </div>
        <Scrollable>
          <Loadable loadingFlags={[isEntitiesLoading]} centered>
            <Grid>
              {entitiesData.items.map((entity: IEntity) => (
                <Entity key={entity.id} entity={entity} onInfoShow={onInfoShow} />
              ))}
            </Grid>
          </Loadable>
        </Scrollable>
        <Pagination
          page={page}
          pageCount={entitiesData.totalPages}
          totalCount={entitiesData.totalCount}
          onPageChange={setPage}
          dataName="entities"
        />
      </div>
      {sidebarEntity && <EntityInfoSidebar entity={sidebarEntity} onClose={onInfoHide} />}
    </div>
  );
};

export default Source;
