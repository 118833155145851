import React from 'react';
import {Modal, Title, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import Button from '../../../../components/Button';
import styles from './DownloadXMLModal.module.css';
import IXmlLink from '../../../../interfaces/IXmlLink';
import PrintLinkRow from './components/PrintLinkRow';
import {Table, THead, Tr, Th, TBody} from '../../../../components/Table';

interface IProps {
  links: IXmlLink[];
  onClose: () => void;
}

const DownloadXMLModal: React.FC<IProps> = ({links = [], onClose}) => {
  return (
    <Modal onClose={onClose}>
      <Title>Print XML Links</Title>
      <div>
        <div className={styles.content}>
          <ModalFormRow>
            <Table className={styles.table}>
              <THead>
                <Tr>
                  <Th>Name</Th>
                  <Th></Th>
                </Tr>
              </THead>
              <TBody>
                {links.map((l, index) => (
                  <PrintLinkRow key={index} title={l.title} url={l.url} />
                ))}
              </TBody>
            </Table>
          </ModalFormRow>
        </div>

        <ModalFooter>
          <Button type="button" className={styles.button} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default DownloadXMLModal;
