import React from 'react';
import styles from './ExtensionStatus.module.css';
import classnames from 'classnames';
import {EXTENSION_STATUS, EXTENSION_STATUSES_MAP} from '../../../../config/constants';

interface IProps {
  status: EXTENSION_STATUS;
}

const ExtensionStatus: React.FC<IProps> = ({status}) => {
  const statusName = EXTENSION_STATUSES_MAP[status];

  switch (status) {
    case EXTENSION_STATUS.DISABLED:
      return <div className={classnames(styles.container, styles.red)}>{statusName}</div>;
    case EXTENSION_STATUS.IN_PROGRESS:
      return <div className={classnames(styles.container, styles.orange)}>{statusName}</div>;
    case EXTENSION_STATUS.READY:
      return <div className={classnames(styles.container, styles.green)}>{statusName}</div>;
    default:
      return null;
  }
};

export default ExtensionStatus;
