import React from 'react';
import {Tr, Td} from '../../../../components/Table';
import WithTooltip from '../../../../components/WithTooltip';
import IconButton from '../../../../components/IconButton';
import DuplicateConfigurationModal from '../DuplicateConfigurationModal';
import {withRouter, RouteComponentProps} from 'react-router';
import paths from '../../../../config/router/paths';
import {DeleteModal} from '../../../../components/Modals';
import EditConfigurationModal from '../EditConfigurationModal';
import {sendAuthorizedRequest} from '../../../../utils/request';
import Icon from '../../../../components/Icon';
import useModal from '../../../../hooks/useModal';
import DateField from '../../../../components/DateField';

interface IProps {
  id: number;
  title: string;
  createdAt: string;
  createdBy: string;
  modifyDate: string;
  updatedBy: string;

  onDuplicate: () => void;
  onDelete: () => void;
  onUpdate: () => void;
}

const ConfigurationRow: React.FC<IProps & RouteComponentProps> = ({
  id,
  title,
  createdAt,
  createdBy,
  modifyDate,
  updatedBy,
  history,
  onDuplicate,
  onDelete,
  onUpdate,
}) => {
  const [isDeleteConfModalOpen, openIsDeleteConfModal, closeIsDeleteConfModal] = useModal();
  const [isDuplicateConfModalOpen, , closeIsDuplicateConfModal] = useModal();
  const [isEditConfModalOpen, openIsEditConfModal, closeIsEditConfModal] = useModal();

  const openConfigurationPage = () => history.push(paths.configuration(id));

  const onConfigurationDelete = async () => {
    try {
      await sendAuthorizedRequest(`/Configuration?id=${id}`, {
        method: 'DELETE',
      });

      onDelete();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {isDeleteConfModalOpen && (
        <DeleteModal
          title={`Are you sure you want to delete the ${title}?`}
          onClose={closeIsDeleteConfModal}
          onDelete={onConfigurationDelete}
        />
      )}
      {isDuplicateConfModalOpen && (
        <DuplicateConfigurationModal rootConfigurationTitle={title} onClose={closeIsDuplicateConfModal} onSuccess={onDuplicate} />
      )}
      {isEditConfModalOpen && (
        <EditConfigurationModal id={id} currentConfigurationTitle={title} onClose={closeIsEditConfModal} onSuccess={onUpdate} />
      )}
      <Tr onClick={openConfigurationPage}>
        <Td>{title}</Td>
        <Td>{createdAt && <DateField date={createdAt} />}</Td>
        <Td>{createdBy}</Td>
        <Td>{modifyDate && <DateField date={modifyDate} />}</Td>
        <Td>{updatedBy}</Td>
        <Td align="flex-end">
          <WithTooltip tip="Edit">
            <IconButton onClick={openIsEditConfModal}>
              <Icon type="pencil" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip>
          <WithTooltip tip="Delete">
            <IconButton onClick={openIsDeleteConfModal}>
              <Icon type="trash" fill="var(--indigo)" width={16} height={20} />
            </IconButton>
          </WithTooltip>
        </Td>
      </Tr>
    </>
  );
};

export default withRouter(ConfigurationRow);
