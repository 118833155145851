import React from 'react';
import './index.css';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface IProps {
  name: string;
  value: EditorState;

  onChange: (name: string, value: EditorState | null) => void;
}

const CustomEditor: React.FC<IProps> = ({name, value, onChange}) => {
  const onLocalChange = (editorState: EditorState) => onChange(name, editorState);

  return (
    <Editor
      toolbar={{
        options: ['inline', 'blockType', 'list'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
        blockType: {
          inDropdown: false,
          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Code'],
        },
        list: {
          options: ['unordered', 'ordered'],
        },
      }}
      editorState={value}
      onEditorStateChange={onLocalChange}
    />
  );
};

export default CustomEditor;
