import React from 'react';
import styles from './FormulaInput.module.css';

interface IProps {
  className?: string;
  value: string;

  onChange: (value: string) => void;
}

const FormulaInput: React.FC<IProps> = ({value = '', onChange, ...props}) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

  return (
    <div className={styles.container}>
      <input className={styles.input} type="text" onChange={onInputChange} value={value || ''} {...props} />
    </div>
  );
};

export default FormulaInput;
