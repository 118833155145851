import React from 'react';
import styles from './Table.module.css';
import classnames from 'classnames';

interface IProps {
  className?: string;
}

const Table: React.FC<IProps> = ({className, children}) => <div className={classnames(styles.table, className)}>{children}</div>;

export default Table;
