import React from 'react';
import styles from './Modal.module.css';

interface IProps {
  align?: 'center' | 'left' | 'right';
}

const Title: React.FC<IProps> = ({align = 'left', children}) => {
  const style = {
    textAlign: align,
  };

  return (
    <div className={styles.title} style={style}>
      {children}
    </div>
  );
};

export default Title;
