import React from 'react';
import {languages} from '../../config/constants';
import Select from 'react-select';
import './index.css';
import LanguageType from '../../types/LanguageType';
import IOption from '../../interfaces/IOption';

interface IProps {
  name: string;
  value: IOption<LanguageType>[] | undefined;

  onChange: (name: string, langs: IOption<LanguageType>[] | undefined) => void;
}

const LanguageSelect: React.FC<IProps> = ({name, value, onChange}) => {
  const onLocalChange = (values: IOption<LanguageType>[]) => {
    if (values === null || values === undefined) {
      onChange(name, undefined);
      return;
    }

    onChange(name, values);
  };
  return (
    <Select classNamePrefix="language-select" isMulti options={languages} placeholder="" onChange={onLocalChange as any} value={value} />
  );
};

export default LanguageSelect;
