import React from 'react';

interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  type:
    | 'arrow'
    | 'box'
    | 'cross'
    | 'duplicate'
    | 'pencil'
    | 'plus'
    | 'trash'
    | 'user'
    | 'download'
    | 'xml-file'
    | 'check-green'
    | 'check-red'
    | 'external-link'
    | 'clipboard'
    | 'test-tube'
    | 'new-file'
    | 'info';
}

const Icon: React.FC<IProps> = React.memo(({type, ...props}) => {
  switch (type) {
    case 'arrow':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.811 240.811" {...props}>
          <path d="M220.088 57.667l-99.671 99.695-99.671-99.707a12.147 12.147 0 00-17.191 0c-4.74 4.752-4.74 12.451 0 17.203l108.261 108.297c4.74 4.752 12.439 4.752 17.179 0L237.256 74.859c4.74-4.752 4.74-12.463 0-17.215-4.728-4.729-12.428-4.729-17.168.023z" />
        </svg>
      );
    case 'box':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 462.035 462.035" {...props}>
          <path d="M457.83 158.441c-.021-.028-.033-.058-.057-.087l-50.184-62.48a11.905 11.905 0 00-1.879-1.845c-2.16-2.562-5.355-4.225-8.967-4.225H65.292c-3.615 0-6.804 1.661-8.965 4.225a11.892 11.892 0 00-1.885 1.845l-50.178 62.48c-.023.029-.034.059-.057.087C1.655 160.602 0 163.787 0 167.39v193.07c0 6.5 5.27 11.771 11.77 11.771h438.496c6.5 0 11.77-5.271 11.77-11.771V167.39c.001-3.603-1.655-6.788-4.206-8.949zm-49.314-23.826l16.873 21.005h-16.873v-21.005zm-23.541-21.27v42.274H296.84c-2.514 0-4.955.805-6.979 2.293l-58.837 43.299-58.849-43.305a11.81 11.81 0 00-6.978-2.287H77.061v-42.274h307.914zM53.523 155.62H36.65l16.873-21.005v21.005zm384.975 193.07H23.54V179.16h137.796l62.711 46.148a11.78 11.78 0 0013.954-.005l62.698-46.144h137.799V348.69z" />
        </svg>
      );
    case 'cross':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.171 241.171" {...props}>
          <path d="M138.138 120.754l99.118-98.576a11.931 11.931 0 000-17.011c-4.74-4.704-12.439-4.704-17.179 0l-99.033 98.492-99.949-99.96c-4.74-4.752-12.439-4.752-17.179 0-4.74 4.764-4.74 12.475 0 17.227l99.876 99.888L3.555 220.497c-4.74 4.704-4.74 12.319 0 17.011 4.74 4.704 12.439 4.704 17.179 0l100.152-99.599 99.551 99.563c4.74 4.752 12.439 4.752 17.179 0 4.74-4.764 4.74-12.475 0-17.227l-99.478-99.491z" />
        </svg>
      );
    case 'duplicate':
      return (
        <svg viewBox="-56 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M395.98 112.582L287.957 4.559A15.592 15.592 0 00276.937 0h-156.34C112 0 105.02 6.98 105.02 15.578v89.445H15.578C6.98 105.023 0 112 0 120.602v375.82C0 505.02 6.98 512 15.578 512h264.36c8.601 0 15.582-6.98 15.582-15.578v-89.445h89.44c8.599 0 15.58-6.977 15.58-15.579V123.602c0-3.961-1.524-7.985-4.56-11.02zM369.383 375.82H295.52V228.625c0-4.035-1.57-8.031-4.563-11.023l-108.02-108.02a15.587 15.587 0 00-11.019-4.562H136.18V31.156h125.18v92.446c0 8.597 6.98 15.578 15.577 15.578h92.446zM264.359 480.844H31.156V136.18h125.18v92.445c0 8.598 6.98 15.578 15.582 15.578h92.441zm-76.863-322.637l54.836 54.836h-54.836zm159.856-50.184h-54.836V53.187c6.855 6.852 49.367 49.368 54.836 54.836zm0 0" />
        </svg>
      );
    case 'pencil':
      return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M487.05 24.918c-33.304-33.223-87.21-33.223-120.51 0L29.081 362.371a17.048 17.048 0 00-4.652 8.691L.348 491.509c-1.883 9.222 4.062 18.222 13.285 20.11 2.254.46 4.574.46 6.828 0l120.527-24.098a17.055 17.055 0 008.692-4.657L487.137 145.41c33.183-33.324 33.148-87.219-.086-120.492zM65.25 374.402L306.273 133.38l72.313 72.312L137.562 446.72zm265.121-265.12l12.067-12.067 72.316 72.312-12.066 12.067zM51.613 408.968L103 460.352l-64.234 12.851zm411.336-287.64l-24.097 24.1-72.313-72.316 24.098-24.097c19.968-19.97 52.343-19.97 72.312 0s19.969 52.343 0 72.312zm0 0" />
        </svg>
      );
    case 'plus':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
          <path d="M492 236H276V20c0-11.046-8.954-20-20-20s-20 8.954-20 20v216H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h216v216c0 11.046 8.954 20 20 20s20-8.954 20-20V276h216c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
        </svg>
      );
    case 'trash':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.428 482.429" {...props}>
          <path d="M381.163 57.799h-75.094C302.323 25.316 274.686 0 241.214 0c-33.471 0-61.104 25.315-64.85 57.799h-75.098c-30.39 0-55.111 24.728-55.111 55.117v2.828c0 23.223 14.46 43.1 34.83 51.199v260.369c0 30.39 24.724 55.117 55.112 55.117h210.236c30.389 0 55.111-24.729 55.111-55.117V166.944c20.369-8.1 34.83-27.977 34.83-51.199v-2.828c0-30.39-24.723-55.118-55.111-55.118zm-139.949-31.66c19.037 0 34.927 13.645 38.443 31.66h-76.879c3.515-18.016 19.406-31.66 38.436-31.66zm134.091 401.173c0 15.978-13 28.979-28.973 28.979H136.096c-15.973 0-28.973-13.002-28.973-28.979V170.861h268.182v256.451zm34.83-311.568c0 15.978-13 28.979-28.973 28.979H101.266c-15.973 0-28.973-13.001-28.973-28.979v-2.828c0-15.978 13-28.979 28.973-28.979h279.897c15.973 0 28.973 13.001 28.973 28.979v2.828z" />
          <path d="M171.144 422.863c7.218 0 13.069-5.853 13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07-7.217 0-13.069 5.854-13.069 13.07v147.154c-.001 7.217 5.851 13.068 13.069 13.068zM241.214 422.863c7.218 0 13.07-5.853 13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07-7.217 0-13.069 5.854-13.069 13.07v147.154c0 7.217 5.851 13.068 13.069 13.068zM311.284 422.863c7.217 0 13.068-5.853 13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07-7.219 0-13.07 5.854-13.07 13.07v147.154c-.001 7.217 5.853 13.068 13.07 13.068z" />
        </svg>
      );
    case 'user':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.118 28.908" {...props}>
          <path d="M11.877 13.925a6.737 6.737 0 004.923-2.04 6.738 6.738 0 002.04-4.923 6.738 6.738 0 00-2.04-4.923 6.961 6.961 0 00-9.846 0 6.737 6.737 0 00-2.04 4.923 6.737 6.737 0 002.04 4.923 6.739 6.739 0 004.923 2.04zM8.152 3.237a5.267 5.267 0 017.45 0 5.034 5.034 0 011.544 3.725 5.034 5.034 0 01-1.544 3.725 5.266 5.266 0 01-7.45 0 5.033 5.033 0 01-1.544-3.725 5.033 5.033 0 011.544-3.725zm0 0" />
          <path d="M24.06 22.229a17.2 17.2 0 00-.234-1.827 14.391 14.391 0 00-.449-1.837 9.072 9.072 0 00-.755-1.713 6.457 6.457 0 00-1.138-1.484 5.02 5.02 0 00-1.635-1.028 5.652 5.652 0 00-2.088-.378 2.119 2.119 0 00-1.132.48c-.339.221-.736.477-1.179.76a6.755 6.755 0 01-1.525.672 5.923 5.923 0 01-3.731 0 6.735 6.735 0 01-1.524-.672c-.439-.28-.836-.536-1.18-.76a2.117 2.117 0 00-1.131-.48 5.645 5.645 0 00-2.088.378 5.015 5.015 0 00-1.636 1.028A6.458 6.458 0 001.5 16.853a9.089 9.089 0 00-.755 1.713 14.426 14.426 0 00-.449 1.836 17.08 17.08 0 00-.234 1.827 24.983 24.983 0 00-.058 1.707 4.8 4.8 0 001.426 3.632 5.132 5.132 0 003.674 1.34h13.915a5.132 5.132 0 003.674-1.34 4.8 4.8 0 001.426-3.632 24.264 24.264 0 00-.059-1.707zm-2.535 4.112a3.455 3.455 0 01-2.506.873H5.1a3.455 3.455 0 01-2.506-.873 3.134 3.134 0 01-.9-2.4c0-.542.018-1.077.054-1.59a15.407 15.407 0 01.212-1.645 12.723 12.723 0 01.4-1.619 7.4 7.4 0 01.614-1.393 4.787 4.787 0 01.837-1.1 3.33 3.33 0 011.088-.676 3.9 3.9 0 011.334-.257c.059.032.165.092.336.2.348.227.75.486 1.193.769a8.393 8.393 0 001.915.856 7.616 7.616 0 004.773 0 8.4 8.4 0 001.916-.856c.454-.29.844-.542 1.193-.769.171-.111.277-.172.336-.2a3.9 3.9 0 011.335.257 3.335 3.335 0 011.088.676 4.774 4.774 0 01.837 1.1 7.375 7.375 0 01.615 1.392 12.694 12.694 0 01.4 1.619 15.536 15.536 0 01.212 1.646c.036.511.054 1.046.054 1.589a3.134 3.134 0 01-.912 2.401zm0 0" />
        </svg>
      );
    case 'download':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471.2 471.2" {...props}>
          <path d="M457.7 230.15c-7.5 0-13.5 6-13.5 13.5v122.8c0 33.4-27.2 60.5-60.5 60.5H87.5c-33.4 0-60.5-27.2-60.5-60.5v-124.8c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v124.8c0 48.3 39.3 87.5 87.5 87.5h296.2c48.3 0 87.5-39.3 87.5-87.5v-122.8c0-7.4-6-13.5-13.5-13.5z" />
          <path d="M226.1 346.75c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4l85.8-85.8c5.3-5.3 5.3-13.8 0-19.1-5.3-5.3-13.8-5.3-19.1 0l-62.7 62.8V30.75c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v273.9l-62.8-62.8c-5.3-5.3-13.8-5.3-19.1 0-5.3 5.3-5.3 13.8 0 19.1l85.9 85.8z" />
        </svg>
      );
    case 'xml-file':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-79 0 512 512" {...props}>
          <path d="M353.102 485.516H0V0h273.656l79.446 79.45zm0 0" fill="#e3e4d8" />
          <path d="M273.656 0v79.45h79.446zm0 0" fill="#d0cebd" />
          <path d="M0 353.102h353.102V512H0zm0 0" fill="#f08509" />
          <g fill="#fff">
            <path d="M52.957 485.516a8.713 8.713 0 01-4.527-1.262c-4.188-2.508-5.535-7.926-3.032-12.11l52.97-88.277c2.503-4.176 7.925-5.527 12.109-3.027 4.183 2.508 5.535 7.926 3.027 12.113l-52.965 88.274a8.83 8.83 0 01-7.582 4.289zm0 0" />
            <path d="M105.941 485.516a8.807 8.807 0 01-7.574-4.282l-52.969-88.273c-2.503-4.184-1.156-9.606 3.032-12.113 4.175-2.508 9.601-1.149 12.11 3.027l52.964 88.277c2.508 4.184 1.156 9.606-3.027 12.114a8.88 8.88 0 01-4.536 1.25zm0 0M300.137 485.516h-52.965c-4.871 0-8.828-3.954-8.828-8.825 0-4.875 3.957-8.828 8.828-8.828h52.965a8.829 8.829 0 018.828 8.828c0 4.872-3.953 8.825-8.828 8.825zm0 0" />
            <path d="M247.172 485.516c-4.871 0-8.828-3.954-8.828-8.825v-88.277a8.829 8.829 0 0117.656 0v88.277c0 4.872-3.953 8.825-8.828 8.825zm0 0M211.863 485.516c-4.875 0-8.828-3.954-8.828-8.825v-55.03l-18.82 32.925c-3.145 5.5-12.184 5.5-15.324 0l-18.82-32.926v55.031c0 4.872-3.958 8.825-8.829 8.825-4.875 0-8.828-3.954-8.828-8.825v-88.277a8.813 8.813 0 016.559-8.527c3.859-1.051 7.945.68 9.93 4.148l27.648 48.375 27.648-48.375c1.988-3.476 6.067-5.2 9.93-4.148a8.817 8.817 0 016.562 8.527v88.277c0 4.872-3.957 8.825-8.828 8.825zm0 0" />
          </g>
          <path
            d="M105.93 273.656a8.824 8.824 0 01-6.785-3.18l-44.141-52.964a8.829 8.829 0 010-11.301l44.14-52.965c3.126-3.742 8.684-4.246 12.426-1.129 3.746 3.125 4.258 8.688 1.133 12.43l-39.418 47.316 39.434 47.317c3.125 3.742 2.613 9.312-1.13 12.425a8.824 8.824 0 01-5.66 2.051zm0 0M247.172 273.656c-1.996 0-4-.672-5.649-2.05-3.742-3.126-4.253-8.684-1.128-12.426l39.421-47.317-39.433-47.316c-3.125-3.742-2.613-9.313 1.133-12.43 3.742-3.117 9.312-2.613 12.425 1.13l44.141 52.964a8.829 8.829 0 010 11.3l-44.14 52.966a8.763 8.763 0 01-6.77 3.18zm0 0M150.059 273.656c-1.325 0-2.672-.3-3.946-.93-4.36-2.18-6.125-7.484-3.945-11.843l52.965-105.934c2.18-4.367 7.488-6.125 11.847-3.945 4.36 2.18 6.125 7.488 3.946 11.848l-52.965 105.93a8.823 8.823 0 01-7.902 4.874zm0 0"
            fill="#83629a"
          />
        </svg>
      );
    case 'check-green':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.054 286.054" {...props} fill="var(--green)">
          <path d="M143.031 0C64.027 0 .004 64.04.004 143.027c0 78.996 64.031 143.027 143.027 143.027 78.987 0 143.018-64.031 143.018-143.027C286.049 64.049 222.018 0 143.031 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.857 26.818 143.031 26.818s116.2 52.026 116.2 116.209-52.025 116.209-116.2 116.209zm56.21-177.049c-6.079-3.629-13.847-1.475-17.342 4.827l-47.959 86.147-26.71-32.512c-4.836-5.569-11.263-8.456-17.333-4.827-6.079 3.638-8.591 12.39-4.657 18.004l37.169 45.241c2.78 3.611 5.953 5.775 9.27 6.392l.027.054.34.018c.751.116 11.979 2.19 16.815-6.463l55.048-98.876c3.493-6.313 1.411-14.367-4.668-18.005z" />
        </svg>
      );
    case 'check-red':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props} fill="var(--red)">
          <path d="M437.016 74.984c-99.979-99.979-262.075-99.979-362.033.002-99.978 99.978-99.978 262.073.004 362.031 99.954 99.978 262.05 99.978 362.029-.002 99.979-99.956 99.979-262.051 0-362.031zm-30.168 331.86c-83.318 83.318-218.396 83.318-301.691.004-83.318-83.299-83.318-218.377-.002-301.693 83.297-83.317 218.375-83.317 301.691 0s83.316 218.394.002 301.689z" />
          <path d="M361.592 150.408c-8.331-8.331-21.839-8.331-30.17 0l-75.425 75.425-75.425-75.425c-8.331-8.331-21.839-8.331-30.17 0s-8.331 21.839 0 30.17l75.425 75.425L150.43 331.4c-8.331 8.331-8.331 21.839 0 30.17 8.331 8.331 21.839 8.331 30.17 0l75.397-75.397 75.419 75.419c8.331 8.331 21.839 8.331 30.17 0 8.331-8.331 8.331-21.839 0-30.17l-75.419-75.419 75.425-75.425c8.331-8.331 8.331-21.838 0-30.17z" />
        </svg>
      );
    case 'external-link':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
          <path d="M14 16V5l-1 1v9H1V3h9l1-1H0v14z" />
          <path d="M16 0h-5l1.8 1.8L6 8.6 7.4 10l6.8-6.8L16 5z" />
        </svg>
      );
    case 'clipboard':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.194 30.194" {...props}>
          <g>
            <path d="M23.823 1.905h-4.804v1.946h3.893c.539 0 .973.436.973.971v22.451a.972.972 0 01-.973.973H7.276a.97.97 0 01-.97-.973V4.822a.97.97 0 01.97-.971h3.953V1.905H6.306a1.946 1.946 0 00-1.947 1.947v24.395c0 1.078.87 1.947 1.947 1.947h17.517c1.078 0 2.012-.869 2.012-1.947V3.852c0-1.075-.934-1.947-2.012-1.947z" />
            <path d="M10.546 4.962c-.416 0-.752.232-.752.518v1.032h10.605V5.48c0-.285-.341-.518-.755-.518H17.38V.74C15.059-.908 12.8.712 12.8.712l.006 4.25h-2.26zM8.713 11.209H21.48v2.456H8.713zM8.713 16.809H21.48v2.454H8.713zM8.713 22.637H21.48v2.456H8.713z" />
          </g>
        </svg>
      );
    case 'info':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" {...props}>
          <path d="M165 0C74.019 0 0 74.02 0 165.001 0 255.982 74.019 330 165 330s165-74.018 165-164.999S255.981 0 165 0zm0 300c-74.44 0-135-60.56-135-134.999S90.56 30 165 30s135 60.562 135 135.001C300 239.44 239.439 300 165 300z" />
          <path d="M164.998 70c-11.026 0-19.996 8.976-19.996 20.009 0 11.023 8.97 19.991 19.996 19.991 11.026 0 19.996-8.968 19.996-19.991 0-11.033-8.97-20.009-19.996-20.009zM165 140c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15v-90c0-8.284-6.716-15-15-15z" />
        </svg>
      );
    case 'test-tube':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 0 512 512" {...props}>
          <path d="M424.047 429.473l-148.29-256.496V30h30V0h-180v30h30v142.977L7.474 429.473c-9.953 17.218-9.965 37.785-.024 55.011C17.387 501.714 35.2 512 55.086 512H376.43c19.89 0 37.699-10.285 47.64-27.516 9.942-17.226 9.93-37.793-.023-55.011zm-238.29-248.45V30h60v151.023L298.356 272h-165.19zm212.329 288.47C393.566 477.323 385.473 482 376.43 482H55.086c-9.04 0-17.133-4.676-21.652-12.508-4.52-7.828-4.516-17.176.011-25.004L115.82 302H315.7l82.374 142.488c4.528 7.828 4.528 17.176.012 25.004zm0 0" />
          <path d="M240.758 331c-24.813 0-45 20.188-45 45s20.187 45 45 45c24.816 0 45-20.188 45-45s-20.184-45-45-45zm0 60c-8.27 0-15-6.73-15-15s6.73-15 15-15c8.273 0 15 6.73 15 15s-6.727 15-15 15zm0 0M150.758 371c0 11.047-8.953 20-20 20-11.043 0-20-8.953-20-20s8.957-20 20-20c11.047 0 20 8.953 20 20zm0 0M350.758 431c0 11.047-8.953 20-20 20-11.043 0-20-8.953-20-20s8.957-20 20-20c11.047 0 20 8.953 20 20zm0 0M170.758 431c0 11.047-8.953 20-20 20-11.043 0-20-8.953-20-20s8.957-20 20-20c11.047 0 20 8.953 20 20zm0 0" />
        </svg>
      );

    case 'new-file':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-23 0 512 512.001" {...props}>
          <path d="M348.945 221.64v-96.894c0-2.773-1.28-5.336-3.093-7.363L237.219 3.309C235.19 1.176 232.309 0 229.429 0H57.196C25.398 0 0 25.93 0 57.73v325.684c0 31.8 25.398 57.305 57.195 57.305h135.953C218.863 483.402 265.605 512 318.852 512c80.886 0 146.941-65.734 146.941-146.727.11-70.75-50.688-129.867-116.848-143.632zM240.102 37.458l72.882 76.723h-47.273c-14.086 0-25.61-11.63-25.61-25.715zM57.195 419.375c-19.953 0-35.851-16.008-35.851-35.96V57.73c0-20.062 15.898-36.386 35.851-36.386h161.563v67.12c0 25.93 21.023 47.06 46.953 47.06h61.89v83.34c-3.199-.106-5.761-.427-8.535-.427-37.242 0-71.496 14.301-97.32 36.711H86.223c-5.871 0-10.672 4.801-10.672 10.668 0 5.872 4.8 10.672 10.672 10.672h115.675c-7.578 10.672-13.875 21.344-18.78 33.082H86.222c-5.871 0-10.672 4.801-10.672 10.672 0 5.867 4.8 10.672 10.672 10.672h89.957c-2.668 10.672-4.055 22.516-4.055 34.36 0 19.206 3.734 38.203 10.457 54.21H57.195zm261.766 71.39c-69.149 0-125.387-56.238-125.387-125.386 0-69.149 56.13-125.387 125.387-125.387 69.254 0 125.383 56.238 125.383 125.387 0 69.148-56.235 125.387-125.383 125.387zm0 0" />
          <path d="M86.223 223.027H194.32c5.871 0 10.672-4.804 10.672-10.672 0-5.87-4.8-10.671-10.672-10.671H86.223c-5.871 0-10.672 4.8-10.672 10.671 0 5.868 4.8 10.672 10.672 10.672zm0 0M326.535 286.625c-2.027-2.133-4.8-3.414-7.789-3.414-2.988 0-5.762 1.281-7.789 3.414l-62.535 67.121c-4.055 4.27-3.734 11.098.535 15.047 4.27 4.055 11.203 3.734 15.258-.531l44.18-47.274v116.528c0 5.87 4.8 10.671 10.671 10.671 5.868 0 10.672-4.8 10.672-10.671V320.988l43.856 47.274c2.136 2.238 4.91 3.414 7.793 3.414 2.558 0 5.12-.961 7.254-2.883 4.27-4.055 4.59-10.777.535-15.047zm0 0" />
        </svg>
      );

    default:
      return null;
  }
});

export default Icon;
