import React from 'react';
import styles from './Checkbox.module.css';

interface IProps {
  onClick: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

const EmptyCheckbox: React.FC<IProps> = ({onClick}) => (
  <label className={styles.container}>
    <input className={styles.input} type="checkbox" onChange={onClick} checked={false} />
    <div className={styles.box} />
  </label>
);

export default EmptyCheckbox;
