export interface ResponseError {
  type: string;
  title: string;
  status: number;
  detail: string;
  traceId: string;
}

export const NAME_COLLISION_STATUS_CODE = 409;

export const isError = <T>(res: T | ResponseError): res is ResponseError => {
  return (res as ResponseError).status !== undefined;
};
