import React from 'react';
import * as Yup from 'yup';
import {Modal, Title, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import {useFormik} from 'formik';
import CustomInput from '../../../../components/CustomInput';
import {IConfiguration} from '../../../../interfaces/IConfiguration';
import IEntity from '../../../../interfaces/IEntity';
import {sendAuthorizedRequest} from '../../../../utils/request';
import LanguageSelect from '../../../../components/LanguageSelect';
import LanguageType from '../../../../types/LanguageType';
import queryString from 'query-string';
import IData from '../../../../interfaces/IData';
import debounce from 'debounce-promise';
import IOption from '../../../../interfaces/IOption';
import {DEFAULT_PAGE_SIZE, ENTITY_TYPE_FOR_FORMULA_TESTING} from '../../../../config/constants';
import TestAutocomplete from '../../../../components/Autocomplete';
import WithLabel from '../../../../components/WithLabel';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';
import IPrint from '../../../../interfaces/IPrint';
import {ResponseError, isError, NAME_COLLISION_STATUS_CODE} from '../../../../utils/isError';

const schema = Yup.object({
  title: Yup.string().required('Title is required'),
  configuration: Yup.object().required('Please select a configuration'),
  entity: Yup.object().required('Please select an entity'),
});

interface IProps {
  onSuccess: () => void;
  onClose: () => void;
}

interface IForm {
  title: string;
  configuration: IOption<IConfiguration> | undefined;
  entity: IOption<IEntity> | undefined;
  codes: IOption<LanguageType>[] | undefined;
}

const CreatePrintModal: React.FC<IProps> = ({onSuccess, onClose}) => {
  const formik = useFormik<IForm>({
    initialValues: {
      title: '',
      configuration: undefined,
      entity: undefined,
      codes: undefined,
    },
    validationSchema: schema,
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await sendAuthorizedRequest<IPrint | ResponseError>('/Print', {
          method: 'PUT',
          body: JSON.stringify({
            name: values.title,
            configuration: {
              id: values.configuration?.value.id,
            },
            entity: {
              id: values.entity?.value.id,
            },
            codes: values.codes!.map((c) => c.value),
          }),
        });

        if (isError(res)) {
          if (res.status === NAME_COLLISION_STATUS_CODE) {
            formikHelpers.setErrors({
              title: 'Print with the same name already exists',
            });
          }
          return;
        }

        onSuccess();
        onClose();
      } catch (err) {}
    },
  });

  const loadConfigurationOptions = async (search: string) => {
    const parsedParams = queryString.stringify({
      search,
    });
    const {items} = await sendAuthorizedRequest<IData<IConfiguration>>(`/Configuration/list/0/${DEFAULT_PAGE_SIZE}?${parsedParams}`);

    return items.map((i) => ({
      label: i.name,
      value: i,
    }));
  };

  const loadEntitiesOptions = async (search: string) => {
    var type = ENTITY_TYPE_FOR_FORMULA_TESTING;
    const parsedParams = queryString.stringify({
      search,
      type,
    });
    const {items} = await sendAuthorizedRequest<IData<IEntity>>(`/Entity/list/0/${DEFAULT_PAGE_SIZE}?${parsedParams}`);
    return items.map((i) => ({
      label: i.title,
      value: i,
    }));
  };

  const debouncedLoadConfigurationOptions = debounce(loadConfigurationOptions, 500, {leading: true});
  const debouncedLoadEntityOptions = debounce(loadEntitiesOptions, 500, {leading: true});

  return (
    <Modal onClose={onClose}>
      <Title>New Print</Title>
      <form onSubmit={formik.handleSubmit}>
        <ModalFormRow>
          <WithLabel label="Title">
            <CustomInput name="title" onChange={formik.handleChange} value={formik.values.title} error={formik.errors.title} />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Configuration">
            <TestAutocomplete<IConfiguration>
              name="configuration"
              error={formik.errors.configuration}
              onChange={formik.setFieldValue}
              fetcher={debouncedLoadConfigurationOptions}
              value={formik.values.configuration}
            />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Source">
            <TestAutocomplete<IEntity>
              name="entity"
              error={formik.errors.entity}
              onChange={formik.setFieldValue}
              fetcher={debouncedLoadEntityOptions}
              value={formik.values.entity}
            />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Language">
            <LanguageSelect name="codes" onChange={formik.setFieldValue} value={formik.values.codes} />
          </WithLabel>
        </ModalFormRow>
        <ModalFooter>
          <ButtonWithSpinner type="submit" disabled={!formik.isValid} submitting={formik.isSubmitting}>
            Create
          </ButtonWithSpinner>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreatePrintModal;
