import React from 'react';
import styles from './Input.module.css';
import classnames from 'classnames';

interface IProps {
  className?: string;
  placeholder?: string;

  onChange: (value: string) => void;
}

const Input: React.FC<IProps> = ({placeholder, ...props}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value);

  return <input className={classnames(props.className, styles.input)} type="text" onChange={onChange} placeholder={placeholder} />;
};

export default Input;
