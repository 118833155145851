import React from 'react';
import styles from './Table.module.css';

interface IProps {
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

const Tr: React.FC<IProps> = ({children, ...props}) => (
  <div className={styles.tr} {...props}>
    {children}
  </div>
);

export default Tr;
