import React from 'react';
import styles from './Entity.module.css';
import CollectionIcon from './typeIcons/collection.svg';
import ElementIcon from './typeIcons/element.svg';
import SetIcon from './typeIcons/set.svg';
import SourceType from '../../types/SourceType';
import WithTooltip from '../WithTooltip';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import paths from '../../config/router/paths';
import IconButton from '../IconButton';
import Icon from '../Icon';
import IEntity from '../../interfaces/IEntity';
import classnames from 'classnames';
import {IBreadcump} from '../Breadcrumbs/Breadcrumbs';

interface IProps {
  entity: IEntity;

  onInfoShow: (entity: IEntity) => void;
}

const Entity: React.FC<IProps & RouteComponentProps> = ({history, location, onInfoShow, entity}) => {
  const isImageExist = !!entity.image;
  const hasChild = entity.entitiesCount > 0;
  const getIcon = (type: SourceType) => {
    switch (type) {
      case 'Collection':
        return CollectionIcon;
      case 'Element':
        return ElementIcon;
      case 'Set':
        return SetIcon;
      default:
        return ElementIcon;
    }
  };
  const descriptionStyles = {
    backgroundSize: isImageExist ? 'contain' : 'auto',
    backgroundImage: `url(${isImageExist ? entity.image + '/thumbnail' : getIcon(entity.type)})`,
  };

  const onEntityInfoClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    onInfoShow(entity);
  };

  const onEntityClick = () => {
    if (!hasChild) {
      return;
    }

    const state = (location.state || []) as IBreadcump[];
    const isEntityExists = state.find((l: any) => l.id === entity.id);

    if (isEntityExists) {
      return;
    }

    history.push(paths.entity(entity.id), [
      ...state,
      {
        id: entity.id,
        type: entity.type,
        title: entity.title,
        sourceId: entity.sourceId,
      },
    ]);
  };
  return (
    <div
      className={classnames(styles.container, {
        [styles.clickable]: hasChild,
      })}
      onClick={onEntityClick}
    >
      <div className={styles.imageWrapper}>
        <div className={styles.image} style={descriptionStyles} />
        <IconButton className={styles.infoButton} onClick={onEntityInfoClick}>
          <Icon type="info" width={16} fill="var(--lynch)" />
        </IconButton>
      </div>
      <WithTooltip tip={entity.title}>
        <div className={styles.title}>{entity.title}</div>
      </WithTooltip>
    </div>
  );
};

export default withRouter(Entity);
