import React, {useState, useEffect} from 'react';
import styles from './Autocomplete.module.css';
import IOption from '../../interfaces/IOption';
import Options from './components/Options';
import Option from './components/Options/components/Option';
import IconButton from '../IconButton';
import Icon from '../Icon';
import classnames from 'classnames';

interface IProps<T> {
  name: string;
  error?: string;
  value: IOption<T> | undefined;

  fetcher: (search: string) => Promise<IOption<T>[]>;
  onChange: (name: string, value: IOption<T> | undefined) => void;
}

function Autocomplete<T>({name, value, error, fetcher, onChange}: IProps<T>) {
  const defaultOptions: IOption<T>[] = [];
  const [options, setOptions] = useState<IOption<T>[]>(defaultOptions);
  const [inputValue, setInputValue] = useState<string>('');
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [focusedOptionId, setFocusedOptionId] = useState<number | undefined>(undefined);
  const isErrorVisible = error !== undefined;
  const isValueExist = value !== undefined;

  const fetchOptions = (search: string) => {
    setIsLoading(true);

    fetcher(search)
      .then((options) => {
        const firstOption = options[0];
        setOptions(options);

        if (firstOption) {
          setFocusedOptionId((firstOption.value as any).id);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchStr = e.target.value;

    setInputValue(searchStr);

    if (searchStr.length === 0) {
      setOptions(defaultOptions);
      setFocusedOptionId(undefined);
      return;
    }

    if (value) {
      onChange(name, undefined);
    }

    fetchOptions(searchStr);
  };

  const onOptionNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentFocusedOptionIndex = options.findIndex((opt) => (opt.value as any).id === focusedOptionId);
    const nextIndex = currentFocusedOptionIndex + 1;
    const prevIndex = currentFocusedOptionIndex - 1;

    if (e.key === 'ArrowUp') {
      const prevFocusedOptionsIndex = prevIndex < 0 ? options.length - 1 : prevIndex;

      setFocusedOptionId((options[prevFocusedOptionsIndex].value as any).id);
    } else if (e.key === 'ArrowDown') {
      const nextFocusedOptionsIndex = nextIndex + 1 > options.length ? 0 : nextIndex;

      setFocusedOptionId((options[nextFocusedOptionsIndex].value as any).id);
    } else if (e.key === 'Enter') {
      selectOption(options[currentFocusedOptionIndex]);
    }
  };

  const focusOption = (option: IOption<T>) => {
    setFocusedOptionId((option.value as any).id);
  };

  const clearData = () => {
    onChange(name, undefined);
    setInputValue('');
    setOptions(defaultOptions);
    setFocusedOptionId(undefined);
  };

  const selectOption = (option: IOption<T>) => {
    onChange(name, option);
    setOptions(defaultOptions);
    setFocusedOptionId(undefined);
  };

  const onFocus = () => {
    setIsOptionsVisible(true);

    if (value) {
      fetchOptions(value.label);
    }
  };

  const onBlur = () => setIsOptionsVisible(false);

  useEffect(() => {
    if (value) {
      setInputValue(value.label);
    }
  }, [value]);

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <input
          type="text"
          className={classnames(styles.input, {
            [styles.withButton]: isValueExist,
          })}
          value={inputValue}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onOptionNavigation}
        />
        {isValueExist && (
          <IconButton className={styles.deleteButton} onClick={clearData}>
            <Icon type="cross" fill="var(--indigo)" width={8} height={8} />
          </IconButton>
        )}
      </div>
      <Options isVisible={isOptionsVisible}>
        {options.length > 0 ? (
          options.map((opt) => {
            const isFocused = (opt.value as any).id === focusedOptionId;
            return (
              <Option<T> key={(opt.value as any).id} option={opt} onClick={selectOption} isFocused={isFocused} onMouseOver={focusOption} />
            );
          })
        ) : (
          <div className={styles.defaultOption}>{isLoading ? 'Loading...' : 'No options'}</div>
        )}
      </Options>
      {isErrorVisible && <div className={styles.error}>{error}</div>}
    </div>
  );
}

export default Autocomplete;
