const paths = {
  dashboard: () => `/dashboard`,
  source: (type?: string) => (type ? `/source?type=${type}` : `/source`),
  entity: (id: number) => `/entity/${id}`,
  configurations: (params: string) => `/configurations?${params}`,
  configuration: (id: number) => `/configurations/${id}`,
  prints: () => `/prints`,
  addPrint: () => `/prints#newPrint`,
};

export default paths;
