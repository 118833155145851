import {useState, useCallback} from 'react';
import {sendAuthorizedRequest} from '../utils/request';
import IEntity from '../interfaces/IEntity';
import {DEFAULT_PAGE_SIZE} from '../config/constants';
import queryString from 'query-string';
import IData from '../interfaces/IData';

const initialData = {
  items: [],
  totalPages: undefined,
  totalCount: undefined,
  pageSize: DEFAULT_PAGE_SIZE,
};

const useEntity = (): [IData<IEntity>, boolean, (id: number, page: number, search: string) => Promise<void>] => {
  const [entity, setEntity] = useState<IData<IEntity>>(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const getAndSaveEntity = useCallback(async (id: number, page: number, search: string) => {
    try {
      setIsLoading(true);
      const filters = queryString.stringify({
        search,
        id,
      });

      const {items, totalPages, totalCount, pageSize} = await sendAuthorizedRequest<IData<IEntity>>(
        `/RelationEntity/list/${page}/${DEFAULT_PAGE_SIZE}?${filters}`
      );

      setEntity({
        items,
        totalPages,
        totalCount,
        pageSize,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return [entity, isLoading, getAndSaveEntity];
};

export default useEntity;
