import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import {cookies} from '../../config/constants';
import styles from './Login.module.css';
import {useDispatch} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import IClaims from '../../interfaces/IClaims';
import {saveTokenClaims} from '../../store/system/actions';

interface IAzureError {
  error_description: string;
  unauthorized_client: null;
}

const getPrefixDataFromUrl = (url: string, prefix: string) => {
  const prefixLength = prefix.length;

  if (url.includes(prefix)) {
    const tokenIndex = url.indexOf(prefix);

    return url.slice(tokenIndex + prefixLength);
  }

  return undefined;
};

const Login: React.FC<RouteComponentProps> = ({history}) => {
  const [error, setError] = useState<IAzureError | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    const aToken = getPrefixDataFromUrl(window.location.href, 'id_token=');
    const error = getPrefixDataFromUrl(window.location.href, 'error=');

    if (error) {
      const parsedError = queryString.parse(error) as any;

      setError(parsedError as IAzureError);
      return;
    }

    if (!aToken) {
      window.location.replace(process.env.REACT_APP_MICROSOFT_LOGIN_URL as string);
      return;
    }

    const claims: IClaims = jwtDecode(aToken);
    // Transform exp timestamp to amount of days until exp
    const daysToExp = (claims.exp - Date.now() / 1000) / (60 * 60 * 24);

    Cookies.set(cookies.accessToken, aToken, {
      expires: daysToExp,
    });

    dispatch(saveTokenClaims(claims));

    history.push('/dashboard');
  }, [dispatch, history]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        window.location.replace(process.env.REACT_APP_MICROSOFT_LOGIN_URL as string);
      }, 3000);
    }
  }, [error]);

  if (error) {
    return <div className={styles.container}>Something went wrong... You will be redirected on Login page</div>;
  }

  return <div className={styles.container}>You will be redirected...</div>;
};

export default Login;
