import React from 'react';
import styles from './CustomInput.module.css';
import classnames from 'classnames';

interface IProps {
  name: string;
  value: string;
  error?: string;
  disabled?: boolean;

  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: React.FC<IProps> = ({error, ...props}) => {
  const isErrorVisible = error !== undefined;

  return (
    <div className={styles.container}>
      <input
        className={classnames(styles.input, {
          [styles.inputError]: isErrorVisible,
        })}
        {...props}
      />
      {isErrorVisible && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default CustomInput;
