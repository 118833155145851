import React from 'react';
import styles from './EntityInfoSidebar.module.css';
import IEntity from '../../interfaces/IEntity';
import IconButton from '../IconButton';
import Icon from '../Icon';

interface IProps {
  entity: IEntity;

  onClose: () => void;
}

const EntityInfoSidebar: React.FC<IProps> = ({ entity, onClose }) => {
  const isImageExist = !!entity.image;
  return (
    <div className={styles.container}>
      <IconButton className={styles.closeButton} onClick={onClose}>
        <Icon type="cross" fill="var(--indigo)" width={16} />
      </IconButton>
      <div className={styles.content}>
        <div className={styles.title}>{entity.title}</div>
        <div className={styles.list}>
          <div className={styles.item}>
            Identificator: <b>{entity.sourceId}</b>
          </div>
          <div className={styles.item}>
            Type: <b>{entity.type}</b>
          </div>
          <div className={styles.item}>
            Child Entities Count: <b>{entity.entitiesCount}</b>
          </div>
          {isImageExist && (
            <div className={styles.item}>
              <div>Image:</div>
              <img className={styles.image} src={entity.image} alt={entity.title} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EntityInfoSidebar;
