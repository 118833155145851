import React, {useEffect} from 'react';
import styles from './Extensions.module.css';
import CustomHelmet from '../../components/CustomHelmet';
import DebouncedInput from '../../components/Inputs';
import usePagination from '../../hooks/usePagination';
import useSearch from '../../hooks/useSearch';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import Icon from '../../components/Icon';
import Scrollable from '../../components/Scrollable';
import {Table, THead, Tr, Th, TBody} from '../../components/Table';
import ExtensionRow from './components/ExtenstionRow';
import Pagination from '../../components/Pagination';
import useModal from '../../hooks/useModal';
import CreateExenstionModal from './components/CreateExenstionModal';
import useExtensions from '../../hooks/useExtensions';
import Loadable from '../../components/Loadable';

const Extensions = () => {
  const [extensions, isLoading, getExtensions] = useExtensions();
  const [search, setSearch] = useSearch('');
  const [page, setPage] = usePagination(0);
  const [isCreateExtensionModalOpen, openCreateExtensionModal, closeCreateExtensionModal] = useModal();

  const refetchExtensions = () => getExtensions(page, search);

  useEffect(() => {
    getExtensions(page, search);
  }, [search, page, getExtensions]);

  return (
    <div className={styles.container}>
      <CustomHelmet title="Magnispot | Formulas" />

      {isCreateExtensionModalOpen && <CreateExenstionModal onClose={closeCreateExtensionModal} onSuccess={refetchExtensions} />}
      <div className={styles.topBar}>
        <div className={styles.topLeft}>
          <DebouncedInput onChange={setSearch} placeholder="Search" />
        </div>
        <div className={styles.topRight}>
          <ButtonWithIcon
            title="New Formula"
            icon={<Icon type="plus" width={14} height={14} fill="#fff" />}
            onClick={openCreateExtensionModal}
          />
        </div>
      </div>
      <Scrollable>
        <Table className={styles.table}>
          <THead>
            <Tr>
              <Th>Name</Th>
              <Th>Status</Th>
              <Th></Th>
            </Tr>
          </THead>

          <Loadable loadingFlags={[isLoading]} centered absolute>
            <TBody>
              {extensions.items.map((ext) => (
                <ExtensionRow
                  key={ext.id}
                  id={ext.id}
                  name={ext.name}
                  description={ext.description}
                  status={ext.status}
                  onEdit={refetchExtensions}
                  onDelete={refetchExtensions}
                />
              ))}
            </TBody>
          </Loadable>
        </Table>
      </Scrollable>
      <Pagination
        page={page}
        pageCount={extensions.totalPages}
        totalCount={extensions.totalCount}
        onPageChange={setPage}
        dataName="formulas"
      />
    </div>
  );
};

export default Extensions;
