import {useState, useCallback} from 'react';
import {DEFAULT_PAGE_SIZE} from '../config/constants';
import {sendAuthorizedRequest} from '../utils/request';
import queryString from 'query-string';
import IEntity from '../interfaces/IEntity';
import IData from '../interfaces/IData';

const initialData = {
  items: [],
  totalPages: undefined,
  totalCount: undefined,
  pageSize: DEFAULT_PAGE_SIZE,
};

const useEntities = (): [IData<IEntity>, boolean, (page: number, search: string, type: string | undefined) => void, () => void] => {
  const [entitiesData, setEntitiesData] = useState<IData<IEntity>>(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const getAndSaveEntities = useCallback(async (page: number = 0, search: string, type: string | undefined) => {
    try {
      setIsLoading(true);
      const filters = queryString.stringify({
        search,
        type,
        sortOrder: [
          JSON.stringify({
            columnName: 'title',
            order: 'asc',
          }),
        ],
      });

      const {items, totalPages, totalCount, pageSize} = await sendAuthorizedRequest<IData<IEntity>>(
        `/Entity/list/${page}/${DEFAULT_PAGE_SIZE}?${filters}`
      );

      setEntitiesData({
        items,
        totalPages,
        totalCount,
        pageSize,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const resetData = () => setEntitiesData(initialData);

  return [entitiesData, isLoading, getAndSaveEntities, resetData];
};

export default useEntities;
