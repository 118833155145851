import React from 'react';
import styles from './WithLabel.module.css';

interface IProps {
  label: string;
}

const WithLabel: React.FC<IProps> = ({label, children}) => {
  return (
    <div>
      <div className={styles.label}>{label}</div>
      <div>{children}</div>
    </div>
  );
};

export default WithLabel;
