import React from 'react';
import styles from './ButtonWithIcon.module.css';

interface IProps {
  icon: React.ReactElement;
  title: string;
  disabled?: boolean;

  onClick: () => void;
}

const ButtonWithIcon: React.FC<IProps> = ({icon, title, ...props}) => (
  <button type="button" className={styles.button} {...props}>
    {icon}
    <span>{title}</span>
  </button>
);

export default ButtonWithIcon;
