import React from 'react';
import styles from './Header.module.css';
import logo from './icons/logo.svg';
import UserPreview from './components/UserPreview';

const Header = () => (
  <div className={styles.container}>
    <img className={styles.logo} src={logo} alt="logo" />
    <div className={styles.preview}>
      <UserPreview />
    </div>
  </div>
);

export default Header;
