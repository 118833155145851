import {useState, useCallback} from 'react';

const useModal = (): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback((e?: React.SyntheticEvent<HTMLButtonElement>) => {
    e && e.stopPropagation();
    setIsOpen(true);
  }, []);

  const close = useCallback((e?: React.SyntheticEvent<HTMLButtonElement>) => {
    e && e.stopPropagation();
    setIsOpen(false);
  }, []);

  return [isOpen, open, close];
};

export default useModal;
