import React from 'react';
import Select from 'react-select';
import './index.css';
import styles from './CustomSelect.module.css';
import IOption from '../../interfaces/IOption';
import './index.css';

interface IProps<T> {
  name: string;
  value: IOption<T> | undefined;
  options: IOption<T>[] | undefined;

  onChange: (name: string, langs: IOption<T> | undefined) => void;
}

function CustomSelect<T>({name, value, options, onChange}: IProps<T>) {
  const onLocalChange = (values: IOption<T>) => {
    if (values === null || values === undefined) {
      onChange(name, undefined);
      return;
    }

    onChange(name, values);
  };
  return (
    <div className={styles.container}>
      <Select
        classNamePrefix="custom-select"
        options={options}
        placeholder=""
        onChange={onLocalChange as any}
        value={value}
        isSearchable={false}
        // menuIsOpen={true}
      />
    </div>
  );
}

export default CustomSelect;
