import React from 'react';
import styles from './Type.module.css';
import SourceType from '../../../../../../types/SourceType';
import classnames from 'classnames';

interface IProps {
  type: SourceType;
  isActive: boolean;

  onClick: (type: string) => void;
}

const Type: React.FC<IProps> = ({type, isActive, ...props}) => {
  const onClick = () => props.onClick(type);

  return (
    <button
      type="button"
      className={classnames(styles.button, {
        [styles.active]: isActive,
      })}
      onClick={onClick}
    >
      {type}
    </button>
  );
};

export default Type;
