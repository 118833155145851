import {applyMiddleware, combineReducers, createStore, Middleware} from 'redux';
import logger from 'redux-logger';
import system from './system/reducers';
import configuration from '../containers/Configuration/state/configuration/reducers';
import fields from '../containers/Configuration/state/fields/reducers';

const reducers = combineReducers({
  system,
  configuration,
  fields,
});

const middlewares: Middleware[] = [];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middlewares));

export type IRootState = ReturnType<typeof reducers>;
export default store;
