import React from 'react';
import * as Yup from 'yup';
import {Modal, Title, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import {useFormik} from 'formik';
import CustomInput from '../../../../components/CustomInput';
import CustomSelect from '../../../../components/CustomSelect';
import {EXTENSION_STATUSES_OPTIONS} from '../../../../config/constants';
import WithLabel from '../../../../components/WithLabel';
import CustomEditor from '../../../../components/CustomEditor';
import IOption from '../../../../interfaces/IOption';
import {sendAuthorizedRequest} from '../../../../utils/request';
import draftToHtml from 'draftjs-to-html';
import {EditorState, convertToRaw} from 'draft-js';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';

const schema = Yup.object({
  name: Yup.string().required('Name is required'),
  status: Yup.object().required('Please select a status'),
});

interface IProps {
  onSuccess: () => void;
  onClose: () => void;
}

interface IForm {
  name: string;
  description: EditorState;
  status: IOption<any> | undefined;
}

const CreateExenstionModal: React.FC<IProps> = ({onSuccess, onClose}) => {
  const formik = useFormik<IForm>({
    initialValues: {
      name: '',
      description: EditorState.createEmpty(),
      status: undefined,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const description = draftToHtml(convertToRaw(values.description.getCurrentContent()));

        await sendAuthorizedRequest('/Extension', {
          method: 'PUT',
          body: JSON.stringify({
            name: values.name,
            status: values.status?.value,
            description,
          }),
        });

        onSuccess();
        onClose();
      } catch (err) {}
    },
  });

  return (
    <Modal onClose={onClose}>
      <Title>New Formula</Title>
      <form onSubmit={formik.handleSubmit}>
        <ModalFormRow>
          <WithLabel label="Name">
            <CustomInput name="name" onChange={formik.handleChange} value={formik.values.name} error={formik.errors.name} />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Status">
            <CustomSelect
              name="status"
              options={EXTENSION_STATUSES_OPTIONS}
              value={formik.values.status}
              onChange={(name: string, value: IOption<any> | undefined) => formik.setFieldValue(name, value)}
            />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Description">
            <CustomEditor
              name="description"
              value={formik.values.description}
              onChange={(name: string, value: EditorState | null) => formik.setFieldValue(name, value)}
            />
          </WithLabel>
        </ModalFormRow>
        <ModalFooter>
          <ButtonWithSpinner type="submit" disabled={!formik.isValid} submitting={formik.isSubmitting}>
            Create
          </ButtonWithSpinner>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateExenstionModal;
