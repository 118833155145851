import React from 'react';
import styles from './Pagination.module.css';
import ReactPaginate from 'react-paginate';
import {DEFAULT_PAGE_SIZE} from '../../config/constants';

interface IProps {
  page: number;
  pageCount: number | undefined;
  totalCount: number | undefined;
  dataName: string;

  onPageChange: (selected: number) => void;
}

const Pagination: React.FC<IProps> = ({page, pageCount = 0, totalCount = 0, dataName, ...props}) => {
  const startPageIndex = totalCount === 0 ? 0 : 1 + page * DEFAULT_PAGE_SIZE;
  const endPageIndex = (page + 1) * DEFAULT_PAGE_SIZE;

  const onPageChange = ({selected}: {selected: number}) => props.onPageChange(selected);
  return (
    <div className={styles.container}>
      <div>
        <ReactPaginate
          previousLabel={<div className={styles.prevIcon} />}
          nextLabel={<div className={styles.nextIcon} />}
          breakLabel="..."
          breakClassName={styles.breaker}
          forcePage={page}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={onPageChange}
          containerClassName={styles.pagination}
          pageLinkClassName={styles.pageButton}
          activeLinkClassName={styles.pageButtonActive}
          previousLinkClassName={styles.navPageButton}
          nextLinkClassName={styles.navPageButton}
        />
      </div>
      <div className={styles.stats}>
        {startPageIndex} - {endPageIndex > totalCount ? totalCount : endPageIndex} of {totalCount} {dataName}
      </div>
    </div>
  );
};

export default Pagination;
