import React, {useState, useEffect} from 'react';
import styles from './ImageInput.module.css';
import Icon from '../Icon';
import IconButton from '../IconButton';

interface IProps {
  name: string;
}

const ALLOWED_MIME_TYPES = ['image/png', 'image/jpeg'];
const MAX_AMOUNT_FILES = 1;

const ImageInput: React.FC<IProps> = ({name}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [isDragError, setIsDragError] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer>('');

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();

    let isErrorFileExist = false;

    if (e.dataTransfer.items.length > MAX_AMOUNT_FILES) {
      isErrorFileExist = true;
    } else {
      isErrorFileExist = Array.prototype.some.call(e.dataTransfer.items, (item) => ALLOWED_MIME_TYPES.indexOf(item.type) < 0);
    }

    setIsDragError(isErrorFileExist);
    setIsDragOver(true);
  };
  const onDragLeave = (e: React.DragEvent) => {
    e.preventDefault();

    setIsDragError(false);
    setIsDragOver(false);
  };
  const onDrop = (e: React.DragEvent) => {
    e.preventDefault();

    setIsDragOver(false);

    if (isDragError) {
      setIsDragError(false);
      return false;
    }

    const targetFile = e.dataTransfer.files[0];

    setFile(targetFile);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const targetFile = e.target.files && e.target.files[0];

    if (targetFile) {
      setFile(targetFile);
    }
  };

  const clearInput = () => {
    setIsDragOver(false);
    setIsDragError(false);
    setFile(undefined);
    setImageSrc('');
  };

  const getIconColor = () => {
    if (isDragError) {
      return 'var(--red)';
    } else if (isDragOver) {
      return 'var(--green)';
    } else {
      return 'var(--gray-300)';
    }
  };

  const getNote = () => {
    if (isDragError) {
      return (
        <span className={styles.error}>
          Only <b>one</b> image (<b>JPG</b> and <b>PNG</b>) could be uploaded.
        </span>
      );
    }
    return (
      <span>
        <b>Choose image to upload (JPG, PNG)</b> or drag it here
      </span>
    );
  };

  useEffect(() => {
    if (file) {
      var reader = new FileReader();
      reader.onload = function(e) {
        if (e.target && e.target.result) {
          setImageSrc(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  if (imageSrc) {
    const imageStyle = {
      backgroundImage: `url(${imageSrc})`,
    };
    return (
      <div style={imageStyle} className={styles.container}>
        <IconButton onClick={clearInput} className={styles.clearButton}>
          <Icon type="cross" width={14} height={14} fill="var(--indigo)" />
        </IconButton>
      </div>
    );
  }

  return (
    <label className={styles.container} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
      <Icon type="new-file" width={48} fill={getIconColor()} />
      <div className={styles.note}>{getNote()}</div>
      <input name={name} className={styles.input} type="file" onChange={onChange} accept="image/jpeg, image/png" multiple={false} />
    </label>
  );
};

export default ImageInput;
