import React from 'react';
import styles from './CreateEditorialModal.module.css';
import {Modal, Title, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import {useFormik} from 'formik';
import CustomInput from '../../../../components/CustomInput';
import Button from '../../../../components/Button';
import CustomTextarea from '../../../../components/CustomTextarea';
import WithLabel from '../../../../components/WithLabel';
import ImageInput from '../../../../components/ImageInput';

interface IProps {
  onClose: () => void;
}

const CreateEditorialModal: React.FC<IProps> = ({onClose}) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      image: '',
      description: '',
    },
    onSubmit: () => {},
  });

  return (
    <Modal onClose={onClose}>
      <Title>New Editorial</Title>
      <form onSubmit={formik.handleSubmit}>
        <ModalFormRow>
          <WithLabel label="Title">
            <CustomInput name="title" onChange={formik.handleChange} value={formik.values.title} error={formik.errors.title} />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Image">
            <ImageInput name="image" />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Description">
            <CustomTextarea
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={formik.errors.description}
            />
          </WithLabel>
        </ModalFormRow>
        <ModalFooter>
          <Button type="submit" className={styles.button} disabled={true}>
            Create
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateEditorialModal;
