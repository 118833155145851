import {IConfiguration} from '../../../../interfaces/IConfiguration';

export const CONFIGURATION_FETCH = '[Configuration] FETCH';
export const CONFIGURATION_FETCH_FAILURE = '[Configuration] FETCH_FAILURE';
export const CONFIGURATION_FETCH_SUCCESS = '[Configuration] FETCH_SUCCESS';
export const CLEAR_CONFIGURATION_STATE = '[Configuration] CLEAR_STATE';

export interface ConfirationFetchAction {
  type: typeof CONFIGURATION_FETCH;
}

export interface ConfirationFetchFailureRequestAction {
  type: typeof CONFIGURATION_FETCH_FAILURE;
  error: string;
}

export interface ConfirationFetchSuccessRequestAction {
  type: typeof CONFIGURATION_FETCH_SUCCESS;
  payload: IConfiguration;
}

export interface IConfigurationState {
  configuration: IConfiguration | undefined;
  isFetching: boolean;
}

export interface ClearConfigurationStateAction {
  type: typeof CLEAR_CONFIGURATION_STATE;
}

export type ConfigurationActionType =
  | ConfirationFetchAction
  | ConfirationFetchFailureRequestAction
  | ConfirationFetchSuccessRequestAction
  | ClearConfigurationStateAction;
