import React from 'react';
import LanguageType from '../../types/LanguageType';
import {languagesMap} from '../../config/constants';

interface IProps {
  code: LanguageType;
}

const Language: React.FC<IProps> = ({code}) => {
  return <span>{languagesMap[code]}</span>;
};

export default Language;
