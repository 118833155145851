import React from 'react';
import MenuItem from './components/MenuItem';
import statusIcon from './icons/status.svg';
import sourceIcon from './icons/source.svg';
import contentIcon from './icons/content.svg';
import configurationsIcon from './icons/configurations.svg';
import formulasIcon from './icons/rules.svg';
import printsIcon from './icons/prints.svg';

const menu = [
  {
    icon: statusIcon,
    title: 'Dashboard',
    path: '/dashboard',
    exact: true,
  },
  {
    icon: sourceIcon,
    title: 'Source',
    path: '/source',
    exact: false,
  },
  {
    icon: contentIcon,
    title: 'Content',
    path: '/content',
    exact: false,
  },
  {
    icon: formulasIcon,
    title: 'Formulas',
    path: '/formulas',
    exact: false,
  },
  {
    icon: configurationsIcon,
    title: 'Configurations',
    path: '/configurations',
    exact: false,
  },
  {
    icon: printsIcon,
    title: 'Prints',
    path: '/prints',
    exact: false,
  },
];

const Menu: React.FC = () => {
  return (
    <div>
      {menu.map((item, index: number) => (
        <MenuItem key={index} item={item} />
      ))}
    </div>
  );
};

export default Menu;
