import React from 'react';
import Menu from './components/Menu';
import styles from './Sidebar.module.css';

const Sidebar = () => (
  <div className={styles.container}>
    <Menu />
  </div>
);

export default Sidebar;
