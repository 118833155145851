import React from 'react';
import styles from './Option.module.css';
import IOption from '../../../../../../interfaces/IOption';
import classnames from 'classnames';

interface IProps<T> {
  option: IOption<T>;
  isFocused: boolean;

  onClick: (option: IOption<T>) => void;
  onMouseOver: (option: IOption<T>) => void;
}

function Option<T>({option, isFocused, onClick, onMouseOver}: IProps<T>) {
  const onMouseDown = () => onClick(option);
  const onHover = () => onMouseOver(option);
  return (
    <button
      type="button"
      className={classnames(styles.container, {
        [styles.focused]: isFocused,
      })}
      onMouseDown={onMouseDown}
      onMouseOver={onHover}
    >
      {option.label}
    </button>
  );
}

export default Option;
