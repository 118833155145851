import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.css';
import Icon from '../Icon';

interface IProps {
  onClose: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const Modal: React.FC<IProps> = ({children, onClose}) => {
  const root = document.querySelector('#modals-slot');

  if (!root) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.container}>
      <div className={styles.modal}>
        <button type="button" className={styles.close} onClick={onClose}>
          <Icon type="cross" width={14} height={14} fill="var(--indigo)" />
        </button>
        {children}
      </div>
    </div>,
    root
  );
};

export default Modal;
