import React from 'react';
import styles from './RootBreadcrump.module.css';

interface IProps {
  type: string | undefined;

  onClick: (type: string) => void;
}

const RootBreadcrump: React.FC<IProps> = ({type, onClick}) => {
  if (!type) {
    return null;
  }

  const onLocalClick = () => onClick(type);
  return (
    <button type="button" className={styles.button} onClick={onLocalClick}>
      {type}s
    </button>
  );
};

export default RootBreadcrump;
