import {useState, useCallback} from 'react';
import {sendAuthorizedRequest} from '../utils/request';
import {DEFAULT_PAGE_SIZE} from '../config/constants';
import IExtension from '../interfaces/IExtension';
import IData from '../interfaces/IData';
import queryString from 'query-string';

const useExtensions = (): [IData<IExtension>, boolean, (page: number, search: string) => void] => {
  const [data, setData] = useState<IData<IExtension>>({
    items: [],
    totalPages: undefined,
    totalCount: undefined,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getAndSaveExtenstions = useCallback(async (page: number, search: string) => {
    try {
      setIsLoading(true);
      const filters = queryString.stringify({
        search,
      });
      const {items, totalPages, totalCount, pageSize} = await sendAuthorizedRequest<IData<IExtension>>(
        `/Extension/list/${page}/${DEFAULT_PAGE_SIZE}?${filters}`
      );

      setData({
        items,
        totalPages,
        totalCount,
        pageSize,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return [data, isLoading, getAndSaveExtenstions];
};

export default useExtensions;
