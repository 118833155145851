import {
  UPDATE_FIELD,
  FIELDS_FETCH,
  FieldsActionType,
  FIELDS_FETCH_FAILURE,
  CLEAR_FIELDS_STATE,
  FIELDS_FETCH_SUCCESS,
  FIELDS_UPDATE,
  FIELDS_UPDATE_SUCCESS,
  FIELDS_UPDATE_FAILURE,
} from './types';
import {IField} from '../../../../interfaces/IField';
import IData from '../../../../interfaces/IData';

export const startFetchFields = (): FieldsActionType => ({
  type: FIELDS_FETCH,
});

export const startUpdateFields = (): FieldsActionType => ({
  type: FIELDS_UPDATE,
});

export const fetchFieldsSuccess = (fields: IData<IField>): FieldsActionType => ({
  type: FIELDS_FETCH_SUCCESS,
  payload: fields,
});

export const updateFieldsSuccess = (): FieldsActionType => ({
  type: FIELDS_UPDATE_SUCCESS,
});

export const updateFieldsFailure = (error: string): FieldsActionType => ({
  type: FIELDS_UPDATE_FAILURE,
  error,
});

export const fetchFieldsFailure = (error: string): FieldsActionType => ({
  type: FIELDS_FETCH_FAILURE,
  error,
});

export const updateField = (field: IField): FieldsActionType => ({
  type: UPDATE_FIELD,
  payload: field,
});

export const clearFieldsState = (): FieldsActionType => ({
  type: CLEAR_FIELDS_STATE,
});
