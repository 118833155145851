import React from 'react';
import styles from './LanguageList.module.css';
import LanguageType from '../../types/LanguageType';
import Language from './Language';

interface IProps {
  codes: LanguageType[];
}

const LanguageList: React.FC<IProps> = ({codes}) => {
  return (
    <ul className={styles.list}>
      {codes.map((c) => (
        <li key={c} className={styles.item}>
          <Language code={c} />
        </li>
      ))}
    </ul>
  );
};

export default LanguageList;
