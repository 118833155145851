import {useState, useCallback} from 'react';
import {IConfiguration} from '../interfaces/IConfiguration';
import {sendAuthorizedRequest} from '../utils/request';
import {DEFAULT_PAGE_SIZE} from '../config/constants';
import queryString from 'query-string';
import IData from '../interfaces/IData';
import ISortOrder from '../interfaces/ISortOrder';

const initialData = {
  items: [],
  totalPages: undefined,
  totalCount: undefined,
  pageSize: DEFAULT_PAGE_SIZE,
};

const useConfigurations = (): [
  IData<IConfiguration>,
  boolean,
  (page: number, search: string, sortOrder: ISortOrder[]) => void,
  () => void
] => {
  const [data, setData] = useState<IData<IConfiguration>>(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const getAndSaveConfigurations = useCallback(async (page: number, search: string, sortOrder: ISortOrder[]) => {
    try {
      setIsLoading(true);
      const parsedParams = queryString.stringify({
        search,
        sortOrder: sortOrder.map((item: ISortOrder) => JSON.stringify(item)),
      });
      const {items, totalPages, totalCount, pageSize} = await sendAuthorizedRequest<IData<IConfiguration>>(
        `/Configuration/list/${page}/${DEFAULT_PAGE_SIZE}?${parsedParams}`
      );

      setData({
        items,
        totalPages,
        totalCount,
        pageSize,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const resetData = () => setData(initialData);

  return [data, isLoading, getAndSaveConfigurations, resetData];
};

export default useConfigurations;
