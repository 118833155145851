import {
  ConfigurationActionType,
  CLEAR_CONFIGURATION_STATE,
  CONFIGURATION_FETCH_SUCCESS,
  CONFIGURATION_FETCH,
  CONFIGURATION_FETCH_FAILURE,
} from './types';
import {IConfigurationState} from './types';

const initialState: IConfigurationState = {
  configuration: undefined,
  isFetching: false,
};

const configuration = (state: IConfigurationState = initialState, action: ConfigurationActionType) => {
  switch (action.type) {
    case CONFIGURATION_FETCH: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case CONFIGURATION_FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case CONFIGURATION_FETCH_SUCCESS: {
      return {
        ...state,
        configuration: action.payload,
        isFetching: false,
      };
    }
    case CLEAR_CONFIGURATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default configuration;
