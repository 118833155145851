import React from 'react';
import Input from '../Input';
import {debounce} from 'debounce';

interface IProps {
  placeholder?: string;

  onChange: (value: string) => void;
}

const DebouncedInput: React.FC<IProps> = ({onChange, ...props}) => <Input onChange={debounce(onChange, 500)} {...props} />;

export default DebouncedInput;
