import React from 'react';
import dayjs from 'dayjs';

interface IProps {
  date: string;
}

const appDateFormat = 'DD.MM.YYYY HH:mm';

const DateField: React.FC<IProps> = ({date}) => <>{dayjs(date).format(appDateFormat)}</>;

export default DateField;
