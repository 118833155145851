import {API_URL, cookies} from '../config/constants';
import Cookies from 'js-cookie';
import ky from 'ky';
import {Options} from 'ky';

const request = async <T>(url: string, config: Options): Promise<T> => {
  try {
    const res = await ky(`${API_URL}${url}`, {
      ...config,
      timeout: false,
    });

    try {
      const data = await res.json();

      return data;
    } catch (err) {
      return new Promise((resolve) => resolve());
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      Cookies.remove(cookies.accessToken);
      window.location.replace(process.env.REACT_APP_MICROSOFT_LOGIN_URL as string);
      return new Promise((resolve) => resolve());
    }
    throw err;
  }
};

export const sendRequest = async <T>(url: string, config: Options = {}): Promise<T> => {
  try {
    const body = await request<T>(url, config);

    return body;
  } catch (err) {
    throw err;
  }
};

export const sendAuthorizedRequest = async <T>(url: string, config: Options = {}): Promise<T> => {
  const aToken = Cookies.get(cookies.accessToken);
  try {
    const body = await sendRequest<T>(url, {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${aToken}`,
      },
    });

    return body;
  } catch (err) {
    throw err;
  }
};
