import React from 'react';
import styles from './Breadcrumbs.module.css';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import paths from '../../config/router/paths';
import Breadcrump from './components/Breadcrump';
import RootBreadcrump from './components/RootBreadcrump';

export interface IBreadcump {
  id: number;
  type: string;
  title: string;
}

interface IProps {
  links: IBreadcump[] | undefined;
}

const Breadcrumbs: React.FC<IProps & RouteComponentProps> = ({links = [], location, history}) => {
  const getRootType = (links: IBreadcump[]) => {
    const firstLink = links[0];

    if (!firstLink) {
      return undefined;
    }

    return firstLink.type;
  };
  const onRootClick = (type: string) => {
    history.push(paths.source(type));
  };
  const onClick = (id: number) => {
    const state = location.state as IBreadcump[];
    const idIndex = state.findIndex((l) => l.id === id);

    history.push(paths.entity(id), state.slice(0, idIndex + 1));
  };
  return (
    <div className={styles.container}>
      <RootBreadcrump type={getRootType(links)} onClick={onRootClick} />
      {links.map((l) => (
        <Breadcrump key={l.id} id={l.id} title={l.title} onClick={onClick} />
      ))}
    </div>
  );
};

export default withRouter(Breadcrumbs);
