import React from 'react';
import * as Yup from 'yup';
import {Modal, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import {useFormik} from 'formik';
import CustomInput from '../../../../components/CustomInput';
import WithLabel from '../../../../components/WithLabel';
import CustomEditor from '../../../../components/CustomEditor';
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {EXTENSION_STATUS, EXTENSION_STATUSES_MAP, EXTENSION_STATUSES_OPTIONS} from '../../../../config/constants';
import CustomSelect from '../../../../components/CustomSelect';
import IOption from '../../../../interfaces/IOption';
import draftToHtml from 'draftjs-to-html';
import {sendAuthorizedRequest} from '../../../../utils/request';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';

const schema = Yup.object({
  id: Yup.number().required(),
  name: Yup.string().required('Name is required'),
  status: Yup.object().required('Please select a status'),
});

interface IProps {
  id: number;
  name: string;
  status: EXTENSION_STATUS;
  description: string;

  onSuccess: () => void;
  onClose: () => void;
}

const getDefaultEditor = (description: string): EditorState => {
  const contentBlock = htmlToDraft(description);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    return editorState;
  }

  return EditorState.createEmpty();
};

const EditExtensionModal: React.FC<IProps> = ({id, name, status, description, onSuccess, onClose}) => {
  const formik = useFormik({
    initialValues: {
      id,
      name,
      status: {
        label: EXTENSION_STATUSES_MAP[status],
        value: status,
      },
      description: getDefaultEditor(description),
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const description = draftToHtml(convertToRaw(values.description.getCurrentContent()));

        await sendAuthorizedRequest('/Extension', {
          method: 'PATCH',
          body: JSON.stringify({
            id: values.id,
            name: values.name,
            status: values.status?.value,
            description,
          }),
        });

        onSuccess();
        onClose();
      } catch (err) {}
    },
  });
  return (
    <Modal onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <ModalFormRow>
          <WithLabel label="Name">
            <CustomInput name="name" onChange={formik.handleChange} value={formik.values.name} error={formik.errors.name} />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Status">
            <CustomSelect
              name="status"
              options={EXTENSION_STATUSES_OPTIONS}
              value={formik.values.status}
              onChange={(name: string, value: IOption<any> | undefined) => formik.setFieldValue(name, value)}
            />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Description">
            <CustomEditor
              name="description"
              value={formik.values.description}
              onChange={(name: string, value: EditorState | null) => formik.setFieldValue(name, value)}
            />
          </WithLabel>
        </ModalFormRow>
        <ModalFooter>
          <ButtonWithSpinner type="submit" disabled={!formik.isValid} submitting={formik.isSubmitting}>
            Save
          </ButtonWithSpinner>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditExtensionModal;
