import React from 'react';
import styles from './Content.module.css';
import CustomHelmet from '../../components/CustomHelmet';
import DebouncedInput from '../../components/Inputs';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import Icon from '../../components/Icon';
import Scrollable from '../../components/Scrollable';
import {Table, THead, Tr, Th, TBody} from '../../components/Table';
import Pagination from '../../components/Pagination';
import useSearch from '../../hooks/useSearch';
import usePagination from '../../hooks/usePagination';
import useModal from '../../hooks/useModal';
import CreateEditorialModal from './components/CreateEditorialModal';

const Content = () => {
  const [, setSearch] = useSearch('');
  const [page, setPage] = usePagination(0);
  const [isCreateEditorialModalOpen, openCreateEditorialModal, closeCreateEditorialModal] = useModal();

  return (
    <div className={styles.container}>
      <CustomHelmet title="Magnispot | Content" />
      {isCreateEditorialModalOpen && <CreateEditorialModal onClose={closeCreateEditorialModal} />}
      <div className={styles.topBar}>
        <div className={styles.topLeft}>
          <DebouncedInput onChange={setSearch} placeholder="Search" />
        </div>
        <div className={styles.topRight}>
          <ButtonWithIcon
            title="New Editorial"
            icon={<Icon type="plus" width={14} height={14} fill="#fff" />}
            onClick={openCreateEditorialModal}
          />
        </div>
      </div>
      <Scrollable>
        <Table className={styles.table}>
          <THead>
            <Tr>
              <Th>Name</Th>
              <Th>Image</Th>
              <Th>Description</Th>
              <Th></Th>
            </Tr>
          </THead>

          {/* <Loadable loadingFlags={[false]} centered absolute> */}
          <TBody></TBody>
          {/* </Loadable> */}
        </Table>
      </Scrollable>
      <Pagination page={page} pageCount={1} totalCount={3} onPageChange={setPage} dataName="editorials" />
    </div>
  );
};

export default Content;
