import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import styles from './Entity.module.css';
import CustomHelmet from '../../components/CustomHelmet';
import usePagination from '../../hooks/usePagination';
import {debounce} from 'debounce';
import ClearableInput from '../../components/ClearableInput';
import useSearch from '../../hooks/useSearch';
import EntityGrid from '../../components/Grid';
import Pagination from '../../components/Pagination';
import useEntity from '../../hooks/useEntity';
import Loadable from '../../components/Loadable';
import Breadcrumbs from '../../components/Breadcrumbs';
import EntityItem from '../../components/Entity';
import IEntity from '../../interfaces/IEntity';
import EntityInfoSidebar from '../../components/EntityInfoSidebar';
import Scrollable from '../../components/Scrollable';
import {IBreadcump} from '../../components/Breadcrumbs/Breadcrumbs';

interface IParams {
  type: string;
  id: string;
}

const Entity: React.FC<RouteComponentProps<IParams>> = ({match, location}) => {
  const id = +match.params.id;
  const [entityData, isLoading, getEntityData] = useEntity();
  const [search, setSearch] = useSearch('');
  const [page, setPage] = usePagination(0);
  const [sidebarEntity, setSidebarEntity] = useState<IEntity | undefined>();

  const onInfoShow = (entity: IEntity) => setSidebarEntity(entity);
  const onInfoHide = () => setSidebarEntity(undefined);

  useEffect(() => {
    getEntityData(id, page, search);
  }, [id, page, search, getEntityData]);

  return (
    <div className={styles.container}>
      <CustomHelmet title="Magnispot | Source" />
      <div className={styles.breadcrumbs}>
        <Breadcrumbs links={location.state as IBreadcump[]} />
      </div>
      <div className={styles.searchBar}>
        <ClearableInput onChange={debounce(setSearch, 500)} />
      </div>
      <Scrollable>
        <Loadable loadingFlags={[isLoading]} centered>
          <EntityGrid>
            {entityData.items.map((entity: IEntity) => (
              <EntityItem key={entity.id} entity={entity} onInfoShow={onInfoShow} />
            ))}
          </EntityGrid>
        </Loadable>
      </Scrollable>
      <Pagination
        page={page}
        pageCount={entityData.totalPages}
        totalCount={entityData.totalCount}
        onPageChange={setPage}
        dataName="entities"
      />
      {sidebarEntity && <EntityInfoSidebar entity={sidebarEntity} onClose={onInfoHide} />}
    </div>
  );
};

export default Entity;
