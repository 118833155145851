import React from 'react';
import {Tr, Td} from '../../../../components/Table';
import Checkbox from '../../../../components/Checkboxes';
import EmptyCheckbox from '../../../../components/Checkboxes/EmptyCheckbox';
import FormulaInput from '../../../../components/FormulaInput';
import useModal from '../../../../hooks/useModal';
import styles from './FieldRow.module.css';
import TestFormulaModal from '../TestFormulaModal';
import {IField} from '../../../../interfaces/IField';

interface IProps {
  configurationId: number;
  field: IField;
  isConfigurationDirty: boolean;

  onFieldUpdate: (field: IField) => void;
  onSaveConfiguration: () => void;
}

const FieldRow: React.FC<IProps> = ({field, configurationId, isConfigurationDirty, onFieldUpdate, onSaveConfiguration}) => {
  const [isTestFormulaModalOpen, openTestFormulaModal, closeTestFormulaModal] = useModal();
  const {id, fullName, formula, isSelected, sourceType, system} = field;

  const onLocalUncheck = () => onFieldUpdate({...field, isSelected: false});
  const onLocalCheck = () => onFieldUpdate({...field, isSelected: true});
  const onFormulaChange = (value: string) => onFieldUpdate({...field, formula: value});

  return (
    <>
      {isTestFormulaModalOpen && (
        <TestFormulaModal
          formula={formula}
          fieldId={id}
          fieldName={fullName}
          configurationId={configurationId}
          isConfigurationDirty={isConfigurationDirty}
          onFormulaChange={onFormulaChange}
          onSaveConfiguration={onSaveConfiguration}
          onClose={closeTestFormulaModal}
        />
      )}
      <Tr>
        <Td>{isSelected ? <Checkbox onClick={onLocalUncheck} /> : <EmptyCheckbox onClick={onLocalCheck} />}</Td>
        <Td>{fullName}</Td>
        <Td>
          <FormulaInput onChange={onFormulaChange} value={formula} />
          <button className={styles.testButton} type="button" onClick={openTestFormulaModal}>
            Test
          </button>
        </Td>
        <Td>{sourceType}</Td>
        <Td>{system}</Td>
      </Tr>
    </>
  );
};

export default FieldRow;
