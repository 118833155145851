import React, {useEffect} from 'react';
import styles from './Configuration.module.css';
import CustomHelmet from '../../components/CustomHelmet';
import useSearch from '../../hooks/useSearch';
import {THead, Table, Tr, Th, TBody} from '../../components/Table';
import Pagination from '../../components/Pagination';
import FieldRow from './components/FieldRow';
import {RouteComponentProps} from 'react-router-dom';
import usePagination from '../../hooks/usePagination';
import Loadable from '../../components/Loadable';
import DebouncedInput from '../../components/Inputs';
import Scrollable from '../../components/Scrollable';
import {IConfiguration} from '../../interfaces/IConfiguration';
import {sendAuthorizedRequest} from '../../utils/request';
import {
  updateField,
  fetchFieldsSuccess,
  clearFieldsState,
  startFetchFields,
  startUpdateFields,
  updateFieldsSuccess,
  updateFieldsFailure,
  fetchFieldsFailure,
} from './state/fields/actions';
import {useSelector, useDispatch} from 'react-redux';
import {IRootState} from '../../store';
import IData from '../../interfaces/IData';
import {IField} from '../../interfaces/IField';
import {DEFAULT_PAGE_SIZE} from '../../config/constants';
import {
  fetchConfigurationSuccess,
  startFetchConfiguration,
  fetchConfigurationFailure,
  clearConfigurationState,
} from './state/configuration/actions';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';

interface IParams {
  id: string;
}

const Configuration: React.FC<RouteComponentProps<IParams>> = ({match}) => {
  const id = +match.params.id;
  const [page, setPage] = usePagination(0);
  const [search, setSearch] = useSearch('');
  const configuration = useSelector((state: IRootState) => state.configuration.configuration);
  const isConfigurationFetching = useSelector((state: IRootState) => state.configuration.isFetching);
  const isFieldsFetching = useSelector((state: IRootState) => state.fields.isFetching);
  const isFieldsUpdating = useSelector((state: IRootState) => state.fields.isUpdating);
  const fields = useSelector((state: IRootState) => state.fields.fields);
  const isDirty = useSelector((state: IRootState) => state.fields.fieldsToUpdate.length > 0);
  const fieldsToUpdate = useSelector((state: IRootState) => state.fields.fieldsToUpdate);
  const dispatch = useDispatch();

  const onSearchChange = (value: string) => {
    setPage(0);
    setSearch(value);
  };

  const onFieldUpdate = (field: IField) => dispatch(updateField(field));
  const saveConfigurationChanges = async () => {
    try {
      dispatch(startUpdateFields());
      await sendAuthorizedRequest(`/Configuration/${id}/field`, {
        method: 'patch',
        body: JSON.stringify(fieldsToUpdate),
      });

      dispatch(updateFieldsSuccess());
    } catch (err) {
      dispatch(updateFieldsFailure(err));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(startFetchConfiguration());
        const configuration = await sendAuthorizedRequest<IConfiguration>(`/Configuration/${id}`);

        dispatch(fetchConfigurationSuccess(configuration));
      } catch (err) {
        dispatch(fetchConfigurationFailure(err));
      }
    })();
  }, [id, dispatch]);

  useEffect(() => {
    (async () => {
      try {
        dispatch(startFetchFields());
        const query = search.length > 0 ? `?search=${search}` : '';
        const fields = await sendAuthorizedRequest<IData<IField>>(`/Configuration/${id}/field/list/${page}/${DEFAULT_PAGE_SIZE}${query}`);

        dispatch(fetchFieldsSuccess(fields));
      } catch (err) {
        dispatch(fetchFieldsFailure(err));
      }
    })();
  }, [id, search, page, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearConfigurationState());
      dispatch(clearFieldsState());
    };
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <CustomHelmet title="Magnispot | Configuration" />
      <div className={styles.title}>
        {isDirty && (
          <ButtonWithSpinner type="button" className={styles.saveButton} onClick={saveConfigurationChanges} submitting={isFieldsUpdating}>
            Save
          </ButtonWithSpinner>
        )}
        {configuration && configuration.name}
      </div>
      <div className={styles.topBar}>
        <div className={styles.topLeft}>
          <DebouncedInput onChange={onSearchChange} placeholder="Search" />
        </div>
      </div>
      <Scrollable>
        <Table className={styles.table}>
          <THead>
            <Tr>
              <Th></Th>
              <Th>Name</Th>
              <Th>Formula</Th>
              <Th>Type</Th>
              <Th>Source</Th>
            </Tr>
          </THead>
          <Loadable loadingFlags={[isFieldsFetching, isConfigurationFetching]} centered absolute>
            <TBody>
              {!!configuration &&
                fields.items.map((f) => (
                  <FieldRow
                    key={f.id}
                    field={f}
                    configurationId={configuration.id}
                    isConfigurationDirty={isDirty}
                    onFieldUpdate={onFieldUpdate}
                    onSaveConfiguration={saveConfigurationChanges}
                  />
                ))}
            </TBody>
          </Loadable>
        </Table>
      </Scrollable>
      <Pagination page={page} pageCount={fields.totalPages} totalCount={fields.totalCount} onPageChange={setPage} dataName="fields" />
    </div>
  );
};

export default Configuration;
