import React, {useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Modal, Title, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import styles from './TestFormulaModal.module.css';
import IEntity from '../../../../interfaces/IEntity';
import {sendAuthorizedRequest} from '../../../../utils/request';
import queryString from 'query-string';
import IData from '../../../../interfaces/IData';
import {DEFAULT_PAGE_SIZE} from '../../../../config/constants';
import debounce from 'debounce-promise';
import IOption from '../../../../interfaces/IOption';
import TestAutocomplete from '../../../../components/Autocomplete';
import CustomTextarea from '../../../../components/CustomTextarea';
import WithLabel from '../../../../components/WithLabel';
import FormulaInput from '../../../../components/FormulaInput';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';

const schema = Yup.object({
  entity: Yup.object().required('Please select an entity'),
});

interface IProps {
  formula: string;
  fieldId: number;
  fieldName: string;
  configurationId: number;
  isConfigurationDirty: boolean;

  onFormulaChange: (value: string) => void;
  onSaveConfiguration: () => void;
  onClose: () => void;
}

const TestFormulaModal: React.FC<IProps> = ({
  formula,
  fieldId,
  configurationId,
  fieldName,
  isConfigurationDirty,
  onFormulaChange,
  onSaveConfiguration,
  onClose,
}) => {
  const [result, setResult] = useState('');
  const formik = useFormik<{
    entity: IOption<IEntity> | undefined;
  }>({
    initialValues: {
      entity: undefined,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        if (isConfigurationDirty) {
          await onSaveConfiguration();
        }

        const {value} =
          (await sendAuthorizedRequest(
            `/Formula/test?EntityId=${values.entity?.value.id}&ConfigurationId=${configurationId}&FieldId=${fieldId}`
          )) || {};
        setResult(value);
      } catch (err) {
        console.error(err);
      }
    },
  });

  const loadEntitiesOptions = async (search: string): Promise<IOption<IEntity>[]> => {
    const parsedParams = queryString.stringify({
      search,
    });
    const type = `&type=${fieldName.split('.')[0]}`;
    const {items} = await sendAuthorizedRequest<IData<IEntity>>(`/Entity/list/0/${DEFAULT_PAGE_SIZE}?${parsedParams}${type}`);

    return items.map((i) => ({
      label: i.title,
      value: i,
    }));
  };

  const debouncedLoadEntityOptions = debounce(loadEntitiesOptions, 500, {leading: true});

  return (
    <Modal onClose={onClose}>
      <Title>Testing</Title>
      <form onSubmit={formik.handleSubmit}>
        <ModalFormRow>
          <WithLabel label="Formula">
            <FormulaInput className={styles.input} onChange={onFormulaChange} value={formula} />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Entity">
            <TestAutocomplete<IEntity>
              name="entity"
              error={formik.errors.entity}
              fetcher={debouncedLoadEntityOptions}
              onChange={formik.setFieldValue}
              value={formik.values.entity}
            />
          </WithLabel>
        </ModalFormRow>
        <ModalFormRow>
          <WithLabel label="Result">
            <CustomTextarea value={result} disabled height={380} />
          </WithLabel>
        </ModalFormRow>
        <ModalFooter>
          <ButtonWithSpinner type="submit" disabled={!formik.isValid} submitting={formik.isSubmitting}>
            {isConfigurationDirty ? 'Save Changes & Test' : 'Test'}
          </ButtonWithSpinner>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default TestFormulaModal;
