import React from 'react';
import styles from './IconButton.module.css';
import classnames from 'classnames';

interface IProps {
  tip?: string;
  id?: string;
  className?: string;

  onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const IconButton: React.FC<IProps> = ({tip, id, className, children, ...props}) => {
  return (
    <button type="button" className={classnames(styles.button, className)} data-for={id} data-tip={tip} {...props}>
      {children}
    </button>
  );
};

export default IconButton;
