import React from 'react';
import {Modal, Title, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import CustomInput from '../../../../components/CustomInput';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {sendAuthorizedRequest} from '../../../../utils/request';
import WithLabel from '../../../../components/WithLabel';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';
import {IConfiguration} from '@microsoft/applicationinsights-web';
import {isError, ResponseError, NAME_COLLISION_STATUS_CODE} from '../../../../utils/isError';

const schema = Yup.object({
  name: Yup.string().required('Title is required'),
});

interface IProps {
  onSuccess: () => void;
  onClose: () => void;
}

const CreateConfigurationModal: React.FC<IProps> = ({onSuccess, onClose}) => {
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: schema,
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await sendAuthorizedRequest<IConfiguration | ResponseError>('/Configuration', {
          method: 'PUT',
          body: JSON.stringify(values),
        });

        if (isError(res)) {
          if (res.status === NAME_COLLISION_STATUS_CODE) {
            formikHelpers.setErrors({
              name: 'Configuration with the same name already exists',
            });
          }
          return;
        }

        onSuccess();
        onClose();
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <Modal onClose={onClose}>
      <Title>New Configuration</Title>
      <form onSubmit={formik.handleSubmit}>
        <ModalFormRow>
          <WithLabel label="Title">
            <CustomInput name="name" onChange={formik.handleChange} value={formik.values.name} error={formik.errors.name} />
          </WithLabel>
        </ModalFormRow>
        <ModalFooter>
          <ButtonWithSpinner type="submit" disabled={!formik.isValid} submitting={formik.isSubmitting}>
            Create
          </ButtonWithSpinner>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateConfigurationModal;
