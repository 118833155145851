import React from 'react';
import styles from './Textarea.module.css';
import classnames from 'classnames';

interface IProps {
  name?: string;
  value: string;
  error?: string;
  disabled?: boolean;
  height?: number;

  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<IProps> = ({error, height, ...props}) => {
  const overideStyles = {
    height: `${height}px`,
  };
  const isErrorVisible = error !== undefined;

  return (
    <div className={styles.container}>
      <textarea
        style={overideStyles}
        className={classnames(styles.textarea, {
          [styles.textareaError]: isErrorVisible,
        })}
        {...props}
      />
      {isErrorVisible && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Textarea;
