import React from 'react';
import Sidebar from './components/Sidebar';
import Header from './components/Header/Header';
import styles from './Dashboard.module.css';

const Dashboard: React.FC = ({children}) => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.contentWrapper}>
        <Sidebar />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Dashboard;
