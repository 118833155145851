import React from 'react';
import {Modal, Title, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import CustomInput from '../../../../components/CustomInput';
import styles from './DuplicateConfigurationModal.module.css';
import Button from '../../../../components/Button';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import WithLabel from '../../../../components/WithLabel';

const schema = Yup.object({
  title: Yup.string().required('Title is required'),
});

interface IProps {
  rootConfigurationTitle: string;

  onSuccess: () => void;
  onClose: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const DuplicateConfigurationModal: React.FC<IProps> = ({rootConfigurationTitle, onClose}) => {
  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {},
  });

  return (
    <Modal onClose={onClose}>
      <Title>Duplicate {rootConfigurationTitle}</Title>
      <form onSubmit={formik.handleSubmit}>
        <ModalFormRow>
          <WithLabel label="Title">
            <CustomInput name="title" onChange={formik.handleChange} value={formik.values.title} error={formik.errors.title} />
          </WithLabel>
        </ModalFormRow>
        <ModalFooter>
          <Button type="button" className={styles.button} disabled={!formik.isValid}>
            Duplicate
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default DuplicateConfigurationModal;
