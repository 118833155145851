import React from 'react';
import Cookies from 'js-cookie';
import {cookies} from '../../config/constants';

const Authorized: React.FC = ({children}) => {
  const aToken = Cookies.get(cookies.accessToken);

  if (aToken === undefined) {
    window.location.replace(process.env.REACT_APP_MICROSOFT_LOGIN_URL as string);
    return null;
  }

  return <>{children}</>;
};

export default Authorized;
