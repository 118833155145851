import React from 'react';
import SourceType from '../../../../types/SourceType';
import {withRouter, RouteComponentProps} from 'react-router';
import styles from './TypesBar.module.css';
import Type from './components/Type';
import paths from '../../../../config/router/paths';
import Scrollable from '../../../../components/Scrollable';

interface IProps {
  types: SourceType[];
  selectedType: SourceType | undefined;

  onChange: (type: SourceType | undefined) => void;
}

const TypesBar: React.FC<IProps & RouteComponentProps> = ({types, selectedType, history, onChange}) => {
  const mainTypes = ['Collection', 'Set'];
  const onClick = (type: string) => {
    const nextType = selectedType === type ? undefined : type;

    onChange(nextType);

    history.push(paths.source(nextType));
  };

  const filterTypes = (types: SourceType[]) => {
    return types.filter((t) => !mainTypes.find((mt) => mt === t)).sort();
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Types:</div>
      <Scrollable>
        <div className={styles.main}>
          {mainTypes.map((type, index) => {
            const isActive = type === selectedType;

            return <Type key={index} type={type} onClick={onClick} isActive={isActive} />;
          })}
        </div>
        {filterTypes(types).map((type, index) => {
          const isActive = type === selectedType;

          return <Type key={index} type={type} onClick={onClick} isActive={isActive} />;
        })}
      </Scrollable>
    </div>
  );
};

export default withRouter(TypesBar);
