import React, {useState, useEffect} from 'react';
import styles from './UserPreview.module.css';
import classnames from 'classnames';
import Cookies from 'js-cookie';
import {cookies} from '../../../../../../config/constants';
import {useSelector} from 'react-redux';
import Icon from '../../../../../Icon';
import IClaims from '../../../../../../interfaces/IClaims';
import {IRootState} from '../../../../../../store';

const emptyUsername = 'unknown';

const UserPreview = () => {
  const claims = useSelector((state: IRootState) => state.system.claims);
  const [isMenuOpen, setIsOpenMenu] = useState(false);
  const menuRef = React.createRef<HTMLDivElement>();

  const toggleMenu = () => setIsOpenMenu(!isMenuOpen);

  const logout = () => {
    Cookies.remove(cookies.accessToken);
    window.location.assign(process.env.REACT_APP_MICROSOFT_LOGIN_URL as string);
  };

  const getUsernamePreview = (claims: IClaims | undefined) => {
    if (!claims) {
      return '';
    }

    if (claims.name === emptyUsername) {
      if (claims.emails) {
        return claims.emails[0];
      }
      return emptyUsername;
    }

    return claims.name;
  };

  useEffect(() => {
    const onDocumentClick = (e: MouseEvent) => {
      const target = e.target;

      if (menuRef && menuRef.current) {
        if (!menuRef.current.contains(target as Node)) {
          setIsOpenMenu(false);
        }
      }
    };

    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [menuRef]);

  return (
    <div className={styles.container}>
      <button type="button" className={styles.preview} onClick={toggleMenu}>
        <Icon type="user" width={24} height={28} fill="var(--indigo)" />
        <span className={styles.username}>{getUsernamePreview(claims)}</span>
        <Icon type="arrow" width={12} height={12} fill="var(--indigo)" />
      </button>
      <div
        className={classnames(styles.menu, {
          [styles.open]: isMenuOpen,
        })}
        ref={menuRef}
      >
        <div className={styles.content}>
          <div className={styles.env}>Production</div>
          <div className={styles.separator} />
          <a className={styles.link} href="https://magnispot.zendesk.com" target="_blank" rel="noopener noreferrer">
            Support
          </a>
          <button className={styles.button} type="button" onClick={logout}>
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserPreview;
