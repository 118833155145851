import {useState, useCallback} from 'react';
import {sendAuthorizedRequest} from '../utils/request';
import SourceType from '../types/SourceType';

const useEntityTypes = (): [SourceType[], boolean, () => void] => {
  const [entityTypes, setEntitiesData] = useState<SourceType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAndSaveEntityTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const types = await sendAuthorizedRequest<SourceType[]>('/Entity/types');

      setEntitiesData(types);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return [entityTypes, isLoading, getAndSaveEntityTypes];
};

export default useEntityTypes;
