import React from 'react';
import styles from './Button.module.css';
import classnames from 'classnames';

interface IProps {
  type?: 'button' | 'submit' | 'reset';
  className: string;
  disabled?: boolean;

  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<IProps> = ({children, className, ...props}) => (
  <button className={classnames(styles.button, className)} {...props}>
    {children}
  </button>
);

export default Button;
