import React from 'react';
import styles from './ButtonWithSpinner.module.css';
import {ReactComponent as Spinner} from './icons/spinner.svg';
import classnames from 'classnames';

interface IProps {
  type: 'submit' | 'button';
  className?: string;
  submitting?: boolean;
  disabled?: boolean;

  onClick?: () => void;
}

const ButtonWithSpinner: React.FC<IProps> = ({className, submitting, children, ...props}) => {
  return (
    <button
      {...props}
      className={classnames(className, styles.button, {
        [styles.submitting]: submitting,
      })}
    >
      {children}
      <Spinner className={styles.spinner} />
    </button>
  );
};

export default ButtonWithSpinner;
