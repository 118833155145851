import React, {useState} from 'react';
import Tooltip from './components/Tooltip';

interface IProps {
  tip: string;

  children: React.ReactElement;
}

const WithTooltip: React.FC<IProps> = ({tip, children}) => {
  const [rect, setRect] = useState<DOMRect | undefined>(undefined);

  const onMouseEnter = (e: React.SyntheticEvent<HTMLElement>) => setRect(e.currentTarget.getBoundingClientRect());
  const onMouseLeave = () => setRect(undefined);

  return (
    <>
      {rect && <Tooltip targetRect={rect}>{tip}</Tooltip>}
      {React.cloneElement(children, {
        onMouseEnter,
        onMouseLeave,
      })}
    </>
  );
};

export default WithTooltip;
