import {useState, useCallback} from 'react';
import {sendAuthorizedRequest} from '../utils/request';
import {DEFAULT_PAGE_SIZE} from '../config/constants';
import IPrint from '../interfaces/IPrint';
import IData from '../interfaces/IData';
import queryString from 'query-string';
import ISortOrder from '../interfaces/ISortOrder';

const usePrints = (): [IData<IPrint>, boolean, (page: number, search: string, sortOrder: ISortOrder[]) => void] => {
  const [data, setData] = useState<IData<IPrint>>({
    items: [],
    totalPages: undefined,
    totalCount: undefined,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getAndSavePrints = useCallback(async (page: number, search: string, sortOrder: ISortOrder[]) => {
    try {
      setIsLoading(true);
      const filters = queryString.stringify({
        search,
        sortOrder: sortOrder.map((item: ISortOrder) => JSON.stringify(item)),
      });
      const {items, totalPages, totalCount, pageSize} = await sendAuthorizedRequest<IData<IPrint>>(
        `/Print/list/${page}/${DEFAULT_PAGE_SIZE}?${filters}`
      );

      setData({
        items,
        totalPages,
        totalCount,
        pageSize,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return [data, isLoading, getAndSavePrints];
};

export default usePrints;
