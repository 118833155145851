import {DEFAULT_PAGE_SIZE} from '../../../../config/constants';
import equal from 'deep-equal';
import {
  IFieldsState,
  FIELDS_FETCH_SUCCESS,
  FieldsActionType,
  UPDATE_FIELD,
  CLEAR_FIELDS_STATE,
  FIELDS_FETCH,
  FIELDS_FETCH_FAILURE,
  FIELDS_UPDATE_SUCCESS,
  FIELDS_UPDATE,
  FIELDS_UPDATE_FAILURE,
} from './types';

const initialState: IFieldsState = {
  origFields: {
    items: [],
    totalCount: undefined,
    totalPages: undefined,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  fields: {
    items: [],
    totalCount: undefined,
    totalPages: undefined,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  fieldsToUpdate: [],
  isFetching: false,
  isUpdating: false,
};

const fields = (state: IFieldsState = initialState, action: FieldsActionType) => {
  switch (action.type) {
    case FIELDS_FETCH: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FIELDS_UPDATE: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case FIELDS_UPDATE_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FIELDS_FETCH_FAILURE: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case FIELDS_FETCH_SUCCESS: {
      /* 
        convert formula null to empty string to avoid
        uncontrolled -> controlled input issue
      */
      const origFields = action.payload.items.map((f) => ({
        ...f,
        formula: f.formula === null ? '' : f.formula,
      }));

      /*
        apply previous changes for a new array of fields
        to see changes which were made on other page
      */
      const fields = origFields.map((f) => {
        const updatedField = state.fieldsToUpdate.find((uf) => uf.id === f.id);

        if (updatedField) {
          return updatedField;
        }

        return f;
      });

      return {
        ...state,
        origFields: {...action.payload, items: origFields},
        fields: {...action.payload, items: fields},
        isFetching: false,
      };
    }
    case FIELDS_UPDATE_SUCCESS: {
      // push selected fields to the top after update
      const sortedFields = state.fields.items
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .sort((a, b) => {
          if (a.isSelected && !b.isSelected) {
            return -1;
          }
          return 0;
        });

      return {
        ...state,
        origFields: {
          ...state.fields,
          items: sortedFields,
        },
        fieldsToUpdate: [],
        isUpdating: false,
      };
    }
    case UPDATE_FIELD: {
      const updatedField = action.payload;
      const defaultField = state.origFields.items.find((f) => f.id === updatedField.id)!;
      const isFieldEqual = equal(updatedField, defaultField);
      const isFieldWasUpdated = state.fieldsToUpdate.some((uf) => uf.id === updatedField.id);
      const newFieldsItems = state.fields.items.map((f) => (f.id === updatedField.id ? updatedField : f));

      return {
        ...state,
        fields: {
          ...state.fields,
          items: newFieldsItems,
        },
        fieldsToUpdate: isFieldEqual
          ? state.fieldsToUpdate.filter((f) => f.id !== updatedField.id)
          : isFieldWasUpdated
          ? // update field which already was updated
            state.fieldsToUpdate.map((uf) => (uf.id === updatedField.id ? updatedField : uf))
          : // add new field to updated fields array
            [...state.fieldsToUpdate, updatedField],
      };
    }
    case CLEAR_FIELDS_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default fields;
