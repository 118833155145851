import React from 'react';
import paths from '../../config/router/paths';
import IEntity from '../../interfaces/IEntity';
import {Link, useHistory} from 'react-router-dom';

interface IProps {
  entity: IEntity;
}

const EntityLink: React.FC<IProps> = ({entity}) => {
  const history = useHistory();
  const onClick = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    history.push(paths.entity(entity.id), [
      {
        id: entity.id,
        type: entity.type,
        title: entity.title,
      },
    ]);
  };

  return (
    <Link to={paths.entity(entity.id)} onClick={onClick}>
      {entity.title}
    </Link>
  );
};

export default EntityLink;
