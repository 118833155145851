import React from 'react';
import styles from './MenuItem.module.css';
import {NavLink} from 'react-router-dom';
import classnames from 'classnames';

interface IProps {
  item: any;
}

const MenuItem = ({item}: IProps) => (
  <NavLink
    to={item.path}
    exact={item.exact}
    className={classnames(styles.container, {
      [styles.disabled]: item.disabled,
    })}
    activeClassName={styles.active}
  >
    <img className={styles.icon} src={item.icon} alt={item.title} />
    {item.title}
  </NavLink>
);

export default MenuItem;
