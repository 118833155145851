import React, {useEffect} from 'react';
import './index.css';
import Router from './Router';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';

var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
  },
});

const App = () => {
  useEffect(() => {
    appInsights.loadAppInsights();
  }, []);

  return <Router />;
};

export default App;
