import React, {useState} from 'react';
import Modal from '../../Modal/Modal';
import Title from '../../Modal/Title';
import styles from './DeleteModal.module.css';
import Button from '../../Button';
import ButtonWithSpinner from '../../ButtonWithSpinner';

interface IProps {
  title: string;

  onClose: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  onDelete: () => void;
}

const DeleteModal: React.FC<IProps> = ({title, onClose, onDelete}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const onLocalDelete = async () => {
    setIsDeleting(true);
    onDelete();
  };

  return (
    <Modal onClose={onClose}>
      <Title align="center">{title}</Title>
      <div className={styles.buttons}>
        <ButtonWithSpinner type="button" className={styles.delete} submitting={isDeleting} onClick={onLocalDelete}>
          Delete
        </ButtonWithSpinner>
        <Button className={styles.close} onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
