import React from 'react';
import styles from './Options.module.css';
import classnames from 'classnames';

interface IProps {
  isVisible: boolean;
}

const Options: React.FC<IProps> = ({isVisible, children}) => (
  <div
    className={classnames(styles.container, {
      [styles.visible]: isVisible,
    })}
  >
    {children}
  </div>
);

export default Options;
