import React from 'react';
import styles from './DetailsExtensionModal.module.css';
import {Modal, Title, ModalFormRow, ModalFooter} from '../../../../components/Modal';
import Button from '../../../../components/Button';
import {EXTENSION_STATUS} from '../../../../config/constants';
import ExtensionNameWithStatus from '../ExtensionNameWithStatus';
import ExtenstionDescription from '../ExtenstionDetails';

interface IProps {
  name: string;
  description: string;
  status: EXTENSION_STATUS;

  onClose: () => void;
}

const DetailsExtensionModal: React.FC<IProps> = ({name, description, status, onClose}) => {
  return (
    <Modal onClose={onClose}>
      <Title>
        <ExtensionNameWithStatus name={name} status={status} />
      </Title>
      <ModalFormRow>
        <ExtenstionDescription description={description} />
      </ModalFormRow>
      <ModalFooter>
        <Button type="submit" className={styles.button} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DetailsExtensionModal;
