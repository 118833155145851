import React from 'react';
import styles from './Breadcrump.module.css';

interface IProps {
  id: number;
  title: string;

  onClick: (id: number) => void;
}

const Breadcrump: React.FC<IProps> = ({id, title, onClick}) => {
  const onLocalClick = () => onClick(id);
  return (
    <button type="button" className={styles.button} onClick={onLocalClick}>
      {title}
    </button>
  );
};

export default Breadcrump;
