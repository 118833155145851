import React from 'react';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import routes from './config/router/routes';
import Dashboard from './components/Dashboard';
import Authorized from './components/Authorized';
import Login from './containers/Login';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Redirect exact={true} from="/" to="/dashboard" />
      <Route exact={true} path="/login" component={Login} />
      <Authorized>
        <Dashboard>
          {routes.map((route, index) => (
            <Route key={index} exact={route.exact} path={route.path} component={route.main} />
          ))}
        </Dashboard>
      </Authorized>
    </Switch>
  </BrowserRouter>
);

export default Router;
