import React from 'react';
import styles from './Dashboard.module.css';
import Card from './components/Card';
import {Link} from 'react-router-dom';
import paths from '../../config/router/paths';
// import {sendAuthorizedRequest} from '../../utils/request';

const Dashboard = () => {
  // const updateMode = async () => {
  //   await sendAuthorizedRequest('/model/update');
  // };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.announce}>
          <div className={styles.title}>Welcome to Magnispot</div>
          <div>Magnispot is a simple, visual platform to help you to plan and launch your publications.</div>
        </div>
      </div>
      <div className={styles.list}>
        <Card>
          <Link to={paths.addPrint()} className={styles.cardLink}>
            <div className={styles.cardTitle}>Plan new Print</div>
            If you want to configure a new data set start here
          </Link>
        </Card>
        <Card>
          <a
            href="https://magnispot.zendesk.com/hc/en-us/sections/360003342380-FAQ"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.cardLink}
          >
            <div className={styles.cardTitle}>Learn About</div>
            Check out our tutorials to help you get started
          </a>
        </Card>
        <Card>
          <a
            href="https://magnispot.zendesk.com/hc/en-us/sections/360003342360-Announcements"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.cardLink}
          >
            <div className={styles.cardTitle}>News</div>
            The latest news on releases, new features and bug fixes
          </a>
        </Card>
        {/* <Card title="Model Information">
          <Button className={styles.button} onClick={updateMode}>
            Update Model
          </Button>
        </Card> */}
      </div>
    </div>
  );
};

export default Dashboard;
