import {IConfiguration} from '../../../../interfaces/IConfiguration';
import {
  ConfigurationActionType,
  CLEAR_CONFIGURATION_STATE,
  CONFIGURATION_FETCH_SUCCESS,
  CONFIGURATION_FETCH,
  CONFIGURATION_FETCH_FAILURE,
} from './types';

export const startFetchConfiguration = (): ConfigurationActionType => ({
  type: CONFIGURATION_FETCH,
});

export const fetchConfigurationFailure = (error: string): ConfigurationActionType => ({
  type: CONFIGURATION_FETCH_FAILURE,
  error,
});

export const fetchConfigurationSuccess = (configuration: IConfiguration): ConfigurationActionType => ({
  type: CONFIGURATION_FETCH_SUCCESS,
  payload: configuration,
});

export const clearConfigurationState = (): ConfigurationActionType => ({
  type: CLEAR_CONFIGURATION_STATE,
});
