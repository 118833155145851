import React, {useState} from 'react';
import styles from './ClearableInput.module.css';
import Icon from '../Icon';

interface IProps {
  onChange: (value: string) => void;
}

const ClearableInput: React.FC<IProps> = (props) => {
  const [value, setValue] = useState('');
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue(value);
    props.onChange(value);
  };
  const clearInput = () => {
    setValue('');
    props.onChange('');
  };

  return (
    <div className={styles.container}>
      <input className={styles.input} type="text" onChange={onChange} value={value} />
      <button type="button" className={styles.clearButton} onClick={clearInput}>
        <Icon type="cross" fill="var(--indigo)" width={8} height={8} />
      </button>
    </div>
  );
};

export default ClearableInput;
