import React from 'react';
import classnames from 'classnames';
import styles from './Loadable.module.css';
import loaderIcon from './icons/loader.svg';
import loaderSecondaryIcon from './icons/loader-secondary.svg';

interface IProps {
  loadingFlags: boolean[];

  absolute?: boolean;
  centered?: boolean;
  secondary?: boolean;
}

const Loadable: React.FC<IProps> = ({loadingFlags, absolute, centered, secondary, children}) => {
  if (loadingFlags.some((f) => f === true)) {
    return (
      <div
        className={classnames(styles.container, {
          [styles.centered]: centered,
          [styles.absolute]: absolute,
        })}
      >
        <img src={secondary ? loaderSecondaryIcon : loaderIcon} className={secondary ? styles.secondaryIcon : styles.icon} alt="loading" />
      </div>
    );
  }

  return <>{children}</>;
};

export default Loadable;
