import React from 'react';
import styles from './Table.module.css';
import classnames from 'classnames';
import SortModeType from '../../types/SortModelType';
import Icon from '../Icon';

interface IProps {
  name: string;
  align?: 'left' | 'center' | 'right';
  sortConf: {
    [name: string]: SortModeType;
  };

  onClick: (name: string) => void;
}

const SortableTh: React.FC<IProps> = ({name, align, sortConf, children, onClick}) => {
  const customStyles = {
    textAlign: align,
  };

  const onElClick = () => onClick(name);

  return (
    <div className={styles.th} style={customStyles} onClick={onElClick}>
      <button
        type="button"
        className={classnames(styles.thButton, {
          [styles.active]: sortConf[name] !== undefined,
          [styles.asc]: sortConf[name] === 'asc',
        })}
      >
        {children}
        <span className={styles.icon}>
          <Icon type="arrow" fill="var(--indigo)" width={8} />
        </span>
      </button>
    </div>
  );
};

export default SortableTh;
