import Source from '../../containers/Source';
import Dashboard from '../../containers/Dashboard';
import Configurations from '../../containers/Configurations';
import Configuration from '../../containers/Configuration';
import Prints from '../../containers/Prints';
import Entity from '../../containers/Entity';
import Extensions from '../../containers/Extensions';
import Content from '../../containers/Content';

const routes = [
  {
    path: '/dashboard',
    exact: true,
    main: Dashboard,
  },
  {
    path: '/source',
    exact: true,
    main: Source,
  },
  {
    path: '/entity/:id',
    exact: true,
    main: Entity,
  },
  {
    path: '/content',
    exact: true,
    main: Content,
  },
  {
    path: '/formulas',
    exact: true,
    main: Extensions,
  },
  {
    path: '/configurations',
    exact: true,
    main: Configurations,
  },
  {
    path: '/configurations/:id',
    exact: true,
    main: Configuration,
  },
  {
    path: '/prints',
    exact: true,
    main: Prints,
  },
];

export default routes;
