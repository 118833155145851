import {useState, useCallback} from 'react';
import SortModeType from '../types/SortModelType';
import ISortOrder from '../interfaces/ISortOrder';

interface ISort {
  [name: string]: SortModeType | undefined;
}

const convertToArray = (sortConf: ISort): ISortOrder[] => {
  return Object.keys(sortConf)
    .map((k: string): any => ({
      columnName: k,
      order: sortConf[k],
    }))
    .filter((item) => item.order !== undefined);
};

const useSort = (initialSortConf: ISort): [ISort, ISortOrder[], (name: string) => void] => {
  const [sortConf, setSortConf] = useState(initialSortConf);
  const [sortConfArray, setSortConfArray] = useState(convertToArray(initialSortConf));

  const updateSort = useCallback(
    (name: string) => {
      const copySortModes = {...sortConf};

      Object.keys(copySortModes).forEach((key: string) => {
        if (key === name) {
          copySortModes[key] = copySortModes[key] === 'asc' ? 'desc' : 'asc';
          return;
        }

        copySortModes[key] = undefined;
      });
      setSortConf(copySortModes);
      setSortConfArray(convertToArray(copySortModes));
    },
    [sortConf]
  );

  return [sortConf, sortConfArray, updateSort];
};

export default useSort;
