import React from 'react';
import {API_URL} from '../../../../../../config/constants';
import WithTooltip from '../../../../../../components/WithTooltip';
import IconButton from '../../../../../../components/IconButton';
import Icon from '../../../../../../components/Icon';
import {Tr, Td} from '../../../../../../components/Table';
import styles from './PrintLinkRow.module.css';

interface IProps {
  title: string;
  url: string;
}

const PrintLinkRow: React.FC<IProps> = ({title, url}) => {
  const extendedUrl = `${API_URL}${url}`;

  const onCopyToClipboard = () => {
    const el = document.createElement('input');
    el.value = extendedUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  return (
    <Tr>
      <Td>{title}</Td>
      <Td align="flex-end">
        <WithTooltip tip="Copy to Clipboard">
          <IconButton onClick={onCopyToClipboard}>
            <Icon type="clipboard" width={16} fill="var(--indigo)" />
          </IconButton>
        </WithTooltip>
        <WithTooltip tip="Preview">
          <a href={extendedUrl} target="_blank" rel="noreferrer noopener" type="application/xml" className={styles.previewLink}>
            <Icon type="external-link" width={16} fill="var(--indigo)" />
          </a>
        </WithTooltip>
      </Td>
    </Tr>
  );
};

export default PrintLinkRow;
